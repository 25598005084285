import React from "react";
import { Container } from "react-bootstrap";
//import {footerCopyRight} from "../data";
import { useTranslation } from "react-i18next";

const CopyRight = () => {
	const { t } = useTranslation();
	return (
		<Container fluid className="footer-bot" aria-label="footerCopyRight">
			<div className="row">
				<div className="col-sm-12">
					<div className="copy-text">
						<div className="mr-2">© { new Date().getFullYear() } {t("copyrightText")}</div>
					
						<span className="tierlogic">
							{/* {footerCopyRight.poweredText} */}
							{t("Powered By ")}
							<a href="https://www.3tierlogic.com/">
								{/*<img alt="3TL-logo" src="https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/assets/home/3tl.png"/>*/}
								<img alt="3TL-logo" src="/public/assets/images/3 tier logic logo - white.png"/>
							</a>
						</span>
					</div>
				</div>
			</div>
		</Container>
	);
};

export default CopyRight;
