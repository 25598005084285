import React, { useState, useEffect } from "react";
import { Row, FormGroup, Modal, Button } from "react-bootstrap";
// eslint-disable-next-line
import { signup, login, getLockupAction, preRegistrationAction } from "../../redux/actions";
import { connect } from "react-redux";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
//import Calendar from "react-calendar";
import Required from "../../components/Required";
import Captcha from "../../components/CaptchaClick";
import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import TermsAndCondition from "../Static/TermsAndCondition";
var validator = require("validator");
const Confirmation = ({ signup, setLoading }) => {
	const { t } = useTranslation();
	const confige = useSelector((state) => state.app.config);
	const history = useHistory();
	const query = new URLSearchParams(window.location.search);
	const referralid = query.get("refCode") ? query.get("refCode") : "";
	const source = query.get("src") ? query.get("src") : "";
	const promoCode = query.get("promoCode") ? query.get("promoCode") : "";
	let [companyList, setCompanyList] = useState([]);
	let [captchaValue, setCaptchaValue] = useState(false);
	let [captchaEnable, setCaptchaEnable] = useState(false);
	const [resetCaptchaCnt, setResetCaptchaCnt] = useState(0);
	const [show, setShow] = useState(false);
	const handleClose = () => {
		setShow(false);
		let d = JSON.parse(JSON.stringify(data));
		d.additionalInfo["agree_ackno_opt_in"] = true;
		setData(d);
	};
	const handleNotAgree = () => {
		setShow(false);
	};
	const handleShow = () => setShow(true);

	const termConditionChkHandler = (checked, name) => {
		let errorArray = JSON.parse(JSON.stringify(error));
		let d = JSON.parse(JSON.stringify(data));
		if (checked) {
			if (name === "agree_ackno_opt_in") {
				handleShow();
				errorArray[name] = t("");
			} else {
				errorArray[name] = t("");
				d.additionalInfo[name] = true;
			}
		} else {
			d.additionalInfo[name] = false;
			errorArray[name] = t("Please review the above and indicate your agreement if you wish to proceed.");
			
		}
		setError(errorArray);
		setData(d);
	};
	const errorMessages = {
		firstName: { reqMsg: "Please enter your first name.", invalidMsg: "First Name must be  maximum 254 characters long and must contain alphabets and special characters only." },
		lastName: { reqMsg: "Please enter your last name.", invalidMsg: "Last Name must be 254 characters long and must contain alphabets and special characters only." },
		email: { reqMsg: "Please enter a valid email address.", invalidMsg: "Please enter a valid email address." },
		confirmEmail: { reqMsg: "Please enter a valid email address.", invalidMsg: "Please enter a valid email address." },
		city: { reqMsg: "Please enter your city.", invalidMsg: "Please enter a valid city name." },
		address: { reqMsg: "Please enter your address.", invalidMsg: "Please enter a valid address." },
		suite: { reqMsg: "Please enter your SUITE/APT. NO.", invalidMsg: "Please enter a valid SUITE/APT. NO." },
		state: { reqMsg: "Please enter your state.", invalidMsg: "Please enter a valid state." },
		zip: { reqMsg: "Please enter your zip code.", invalidMsg: "Please enter a valid zip code." },
		cellPhone: { reqMsg: "Please enter valid phone number.", invalidMsg: "Please enter valid phone number." },
		birth: { reqMsg: "Please enter your valid DOB.", invalidMsg: "Please enter your valid DOB." },
		companyName: { reqMsg: "Please select Company.", invalidMsg: "Please select Company." },
		socialSecurityNumber: { reqMsg: "Please enter a valid social security number.", invalidMsg: "Please enter a valid social security number." },
	};

	/*[START:INITIALIZE_DECLARATION]*/
	const [data, setData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		confirmEmail: "",
		cellPhone: "",
		companyName: "",
		password: "",
		passwordConfirm: "",
		additionalInfo: { visitorId: "", requestId: "", agree_ackno_opt_in: false, agree_to_opt_in: false, agree_to_program_scope: false, agree_to_sign_aggr: false, agree_to_my_computer: false, agree_to_strickly_limited: false, agree_to_in_connection: false, agree_to_reserves_rights: false },
	});
	/*[END:INITIALIZE_DECLARATION]*/
	const [validation, setValidation] = useState({});
	const [error, setError] = useState({ firstName: "", email: "", confirmEmail: "", cellPhone: "", lastName: "", captcha: "", agree_to_opt_in: "", agree_to_program_scope: "", agree_ackno_opt_in: "", agree_to_sign_aggr: "", agree_to_my_computer: "", agree_to_strickly_limited: "", agree_to_in_connection: "", agree_to_reserves_rights: "" });
	useEffect(() => {
		let items = {};
		let oldState = { ...data, password: "ChangMe@123", passwordConfirm: "ChangMe@123" };
		setData(oldState);
		if (confige.uiConfig) {
			confige.uiConfig.userFields.forEach((element) => {
				items[element.name] = element.validation;
				if (element.name === "email") {
					items["confirmEmail"] = element.validation;
				}
			});
			items.password = confige.uiConfig.passwordRule;
			items.passwordConfirm = confige.uiConfig.passwordRule;
			if (confige.fingerprinting) {
				if (confige.fingerprinting.enable) {
					const fpPromise = FingerprintJS.load({ apiKey: confige.fingerprinting.clientKey });
					// Get the visitor identifier when you need it.
					fpPromise
						.then((fp) => fp.get())
						.then((result) => {
							let fdata = JSON.parse(JSON.stringify(data));
							fdata.additionalInfo.visitorId = result.visitorId;
							fdata.additionalInfo.requestId = result.requestId;
							setData(fdata);
						});
				}
			}
		}
		if (confige.captchaConfig) {
			if (confige.captchaConfig.registrationCaptcha) {
				setCaptchaEnable(true);
			}
		}
		setValidation(items);
		// eslint-disable-next-line
	}, [confige, confige.uiConfig]);
	const handleChange = (e) => {
		const { name, value } = e.target;
		let errorMessage = "";
		let validationArray = validation[name];
		let check = false;
		if (name === "agree_to_opt") {
			let d = JSON.parse(JSON.stringify(data));
			d.additionalInfo.agree_to_opt_in = e.target.checked;
			setData(d);
			return true;
		}
		for (let key in validationArray) {
			if (key === "minLength") {
				if (value.length < validationArray[key] && value.length !== 0) {
					errorMessage = errorMessages[name]
						? errorMessages[name].invalidMsg
						: t("Should not be less then", {
								count: validationArray[key],
						  });
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "maxLength") {
				if (value.length > validationArray[key]) {
					errorMessage = errorMessages[name]
						? errorMessages[name].invalidMsg
						: t("Should not be greater then", {
								count: validationArray[key],
						  });
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "mandatory" && validationArray[key]) {
				if (!value.length) {
					errorMessage = errorMessages[name] ? errorMessages[name].reqMsg : t("Should not be empty");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "hasNumeric" && validationArray[key] && value.search(/[0-9]/) < 0) {
				errorMessage = t("Should be contain number");
				setError({ ...error, [name]: errorMessage });
				check = true;
			}
			if (key === "hasAlpha" && validationArray[key] && value.search(/[A-Z]/) < 0 && value.search(/[a-z]/) < 0) {
				errorMessage = t("Should be contain alphabets");
				setError({ ...error, [name]: errorMessage });
				check = true;
			}
			if (key === "email" && validationArray[key]) {
				if (!validator.isEmail(value)) {
					errorMessage = errorMessages.email ? errorMessages.email.invalidMsg : t("Please enter a valid email");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && !validationArray[key] && value.search(/[0-9]/) >= 0) {
				errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("Should not be contain number");
				setError({ ...error, [name]: errorMessage });
				check = true;
			} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && validationArray[key] && value.search(/[0-9]/) < 0) {
				errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("Should be contain number");
				setError({ ...error, [name]: errorMessage });
				check = true;
			}
			if (key === "upperCaseReq" && validationArray[key]) {
				if (value.search(/[A-Z]/) < 0 && value.length !== 0) {
					errorMessage = t("Should have atleast one Upper Case latter");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "lowerCaseReq" && validationArray[key]) {
				if (value.search(/[a-z]/) < 0 && value.length !== 0) {
					errorMessage = t("Should have atleast one Lower Case latter");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "specialCharReq" && validationArray[key]) {
				var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
				if (!format.test(value) && value.length !== 0) {
					errorMessage = t("Should have atleast one special letter");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}

			if (name === "passwordConfirm") {
				if (data.password !== value) {
					setError({
						...error,
						passwordConfirm: t("Password and confirm password should be same"),
					});
				}
			}
		}

		if (!check) {
			setError({ ...error, [name]: "" });
		}
		setData({ ...data, [name]: value });
	};
	useEffect(() => {
		let errorArray = JSON.parse(JSON.stringify(error));
		if (captchaValue) {
			errorArray["captcha"] = "";
		}
		setError(errorArray);
	}, [captchaValue]);
	const onSubmit = async () => {
		let errorArray = {};
		for (let name in data) {
			let value = data[name];
			let validationArray = validation[name];
			let errorMessage = "";
			for (let key in validationArray) {
				if (key === "email" && validationArray[key] && !validator.isEmail(value)) {
					errorMessage = errorMessages.email ? errorMessages.email.invalidMsg : t("Please enter a valid email");
				} else if (key === "minLength" && value.length < validationArray[key] && value.length !== 0) {
					errorMessage = errorMessages[name]
						? errorMessages[name].invalidMsg
						: t("Should not be less then", {
								count: validationArray[key],
						  });
				} else if (key === "maxLength" && value.length > validationArray[key]) {
					errorMessage = errorMessages[name]
						? errorMessages[name].invalidMsg
						: t("Should not be greater then", {
								count: validationArray[key],
						  });
				} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && !validationArray[key] && value.search(/[0-9]/) >= 0) {
					errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("Should not be contain number");
				} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && validationArray[key] && value.search(/[0-9]/) <= 0) {
					errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("Should be contain number");
				} else if (key === "mandatory" && validationArray[key] && !value.length) {
					errorMessage = errorMessages[name] ? errorMessages[name].reqMsg : t("Should not be empty");
				} else if (key === "upperCaseReq" && value.search(/[A-Z]/) < 0 && value.length !== 0) {
					errorMessage = t("Should have atleast one Upper Case latter");
				} else if (key === "lowerCaseReq" && value.search(/[a-z]/) < 0 && value.length !== 0) {
					errorMessage = t("Should have atleast one Lower Case latter");
				} else if (key === "hasNumeric" && validationArray[key] && value.search(/[0-9]/) < 0) {
					errorMessage = t("Should be contain number");
				} else if (key === "hasAlpha" && validationArray[key] && value.search(/[A-Z]/) < 0 && value.search(/[a-z]/) < 0) {
					errorMessage = t("Should be contain alphabets");
				} else if (key === "specialCharReq" && value.length !== 0) {
					var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
					if (!format.test(value)) {
						errorMessage = t("Should have atleast one special letter");
					}
				}
			}
			errorArray[name] = errorMessage;
			if (name === "password" || name === "passwordConfirm") {
				if (data["password"] !== data["passwordConfirm"]) {
					errorMessage = t("Password and confirm password should be same");
				}
				errorArray["passwordConfirm"] = errorMessage;
			}
			if (name === "confirmEmail") {
				if (data["email"] !== data["confirmEmail"]) {
					errorMessage = t("Email and confirm email should be same");
				}
				errorArray["confirmEmail"] = errorMessage;
			}
		}
		if (!captchaValue && captchaEnable) {
			errorArray["captcha"] = t("Please select captcha");
		}
		// if (!data.additionalInfo.agree_to_opt_in) {
		// 	errorArray.agree_to_opt_in = t("Please review the above and indicate your agreement if you wish to proceed.");
		// }
		if (!data.additionalInfo.agree_to_sign_aggr) {
			errorArray.agree_to_sign_aggr = t("Please review the above and indicate your agreement if you wish to proceed.");
		}
		if (!data.additionalInfo.agree_to_program_scope) {
			errorArray.agree_to_program_scope = t("Please review the above and indicate your agreement if you wish to proceed.");
		}
		if (!data.additionalInfo.agree_ackno_opt_in) {
			errorArray.agree_ackno_opt_in = t("Please review the above and indicate your agreement if you wish to proceed.");
		}
		if (!data.additionalInfo.agree_to_my_computer) {
			errorArray.agree_to_my_computer = t("Please review the above and indicate your agreement if you wish to proceed.");
		}
		if (!data.additionalInfo.agree_to_strickly_limited) {
			errorArray.agree_to_strickly_limited = t("Please review the above and indicate your agreement if you wish to proceed.");
		}
		if (!data.additionalInfo.agree_to_in_connection) {
			errorArray.agree_to_in_connection = t("Please review the above and indicate your agreement if you wish to proceed.");
		}
		if (!data.additionalInfo.agree_to_reserves_rights) {
			errorArray.agree_to_reserves_rights = t("Please review the above and indicate your agreement if you wish to proceed.");
		}
		setError(errorArray);
		let check = false;
		for (let key in errorArray) {
			if (errorArray[key]) {
				check = true;
			}
		}
		if (!captchaValue && captchaEnable) {
			return false;
		}
		console.log("errorArray", errorArray);
		const newData = { ...data };
		if (captchaValue !== "none") {
			newData["captchaVal"] = captchaValue;
		}
		delete newData.passwordConfirm;
		if (!check) {
			if (!newData.hasOwnProperty("additionalInfo")) {
				newData["additionalInfo"] = {};
			}
			if (promoCode) {
				newData["additionalInfo"]["promoCode"] = promoCode;
			}
			if (source) {
				newData["additionalInfo"]["source"] = source;
			}
			setLoading(true);
			try {
				let signupCall = true;
				if (confige.usePreRegistration) {
					signupCall = false;
					let preRegistrationResp = await preRegistrationAction(newData);
					if (preRegistrationResp.data) {
						if (preRegistrationResp.data.valid) {
							signupCall = true;
							newData.additionalInfo.token = preRegistrationResp.data.token;
						} else {
							toast.error(preRegistrationResp.data.errorReason);
							setLoading(false);
							setResetCaptchaCnt(resetCaptchaCnt + 1);
							setCaptchaValue(false);
						}
					} else {
						toast.error(t("Something went wrong"));
						setLoading(false);
						setResetCaptchaCnt(resetCaptchaCnt + 1);
						setCaptchaValue(false);
					}
				}
				if (signupCall) {
					let signupResp = await signup(newData, referralid);
					setResetCaptchaCnt(resetCaptchaCnt + 1);
					setCaptchaValue(false);
					toast.success("Thank you! You have been registered for the program.");
					history.push("/");
					window.scroll({
						top: 0,
						left: 100,
						behavior: "smooth",
					});
					setLoading(false);
				}
			} catch (error) {
				setResetCaptchaCnt(resetCaptchaCnt + 1);
				setCaptchaValue(false);
				console.log("errror", error);
				setLoading(false);
				if (Array.isArray(error)) {
					error.map((item) => {
						toast.error(item.message);
					});
				} else {
					toast.error(t("Something went wrong"));
				}
			}
		}
	};

	useEffect(() => {
		let requestData = { lookups: ["COMPANY_LIST"] };
		getLockupAction(requestData).then((response) => {
			if (response.httpCode === 200 && response.data) {
				if (response.data.content) {
					let comListInd = response.data.content.findIndex((v) => v.name === "COMPANY_LIST");
					if (comListInd >= 0) {
						let dt=response.data.content[comListInd].data.sort((a,b) =>a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
						setCompanyList(dt);
					}
				}
			}
		});
	}, []);
	return (
		<div className="register-page">
			<div className="col-sm-10">
				<form role="form" aria-label="Confirmation">
					<div className="text-center">
						<h2>For Category Merchant or Sales Manager authorized to certify on behalf of their company</h2>
						<p>This is required once per Distributor/ReDistributor</p>
					</div>
					<h1 className="main-heading">
						<Trans>Confirmation</Trans>
					</h1>
					<Row>
						{/*[START:FIRSTNAME]*/}
						<div className="form-group col-sm-6 theme-signup-firstname">
							<label htmlFor="first-name" className="col-form-label">
								<Trans>lang_key_fname</Trans>
								{validation["firstName"] && validation["firstName"].mandatory ? <Required /> : ""}
							</label>
							<div className="">
								<input type="text" 
								data-test="register-firstName" 
								name="firstName" 
								onChange={handleChange} 
								className="form-control" 
								id="first-name" 
								placeholder={t("lang_key_fname")} 
								aria-label="Enter Your Name"
								aria-autocomplete="name"
								/>
								<span className="input-error" role="alert">{error.firstName}</span>
							</div>
						</div>
						{/*[END:FIRSTNAME]*/}

						{/*[START:LASTNAME]*/}
						<div className="form-group col-sm-6 theme-signup-lastname">
							<label htmlFor="last-name" className=" col-form-label">
								<Trans>lang_key_lname</Trans>
								{validation["lastName"] && validation["lastName"].mandatory ? <Required /> : ""}
							</label>
							<div className="">
								<input type="text" 
								data-test="register-lasttName" 
								name="lastName" 
								onChange={handleChange} 
								className="form-control" 
								id="last-name" 
								placeholder={t("lang_key_lname")} 
								aria-label="Enter Family Name"
								aria-autocomplete="family-name"
								/>
								<span className="input-error" role="alert">{error.lastName}</span>
							</div>
						</div>
						{/*[END:LASTNAME]*/}
					</Row>
					<Row>
						{/*[START:EMAIL]*/}
						<div className="form-group col-sm-6 theme-signup-email">
							<label htmlFor="email" className="col-form-label">
								<Trans>lang_key_email</Trans>
								{validation["email"] && validation["email"].mandatory ? <Required /> : ""}
							</label>
							<div className="">
								<input type="email" 
								data-test="register-email" 
								name="email" 
								onChange={handleChange} 
								className="form-control" 
								id="email" 
								placeholder={t("lang_key_email")} 
								aria-label="Enter Email"
								aria-autocomplete="email"
								/>
								<span className="input-error" role="alert">{error.email}</span>
							</div>
						</div>
						{/*[END:EMAIL]*/}
						{/*[START:EMAIL CONFIRM]*/}
						<div className="form-group col-sm-6 theme-signup-email">
							<label htmlFor="email-confirm" className="col-form-label">
								<Trans>lang_key_email_confirm</Trans>
								{validation["confirmEmail"] && validation["confirmEmail"].mandatory ? <Required /> : ""}
							</label>
							<div className="">
								<input type="email" 
								data-test="register-email" 
								name="confirmEmail" 
								onChange={handleChange} 
								className="form-control" 
								id="email-confirm" 
								placeholder={t("lang_key_email_confirm")} 
								aria-label="Re-enter Email"
								aria-autocomplete=""
								/>
								<span className="input-error" role="alert">{error.confirmEmail}</span>
							</div>
						</div>
						{/*[END:EMAIL CONFIRM]*/}
					</Row>
					{/* <Row>
						<div className="form-group col-sm-6 theme-signup-password">
							<label htmlFor="password-name" className=" col-form-label">
								<Trans>password</Trans>
								{validation["password"] && validation["password"].mandatory ? <Required /> : ""}
							</label>
							<div className="">
								<input type="password" data-test="register-password" name="password" onChange={handleChange} className="form-control" id="password" placeholder={t("password")} />
								<span className="input-error">{error.password}</span>
							</div>
						</div>
						<div className="form-group col-sm-6 theme-signup-password">
							<label htmlFor="password-confirm" className=" col-form-label">
								<Trans>confirm password</Trans>
								{validation["passwordConfirm"] && validation["passwordConfirm"].mandatory ? <Required /> : ""}
							</label>
							<div className="">
								<input type="password" data-test="register-password-confirm" name="passwordConfirm" onChange={handleChange} className="form-control" id="password-confirm" placeholder={t("confirm password")} />
								<span className="input-error">{error.passwordConfirm}</span>
							</div>
						</div>
					</Row> */}
					<Row>
						{/*[START:COMPANYNAME]*/}
						<div className="form-group col-sm-12 theme-signup-companyName">
							<label htmlFor="companyName" className="col-form-label">
								<Trans>Company Name</Trans>
								{validation["companyName"] && validation["companyName"].mandatory ? <Required /> : ""}
							</label>
							<div className="">
								<select data-test="signup-companyName" name="companyName" onChange={handleChange} className="form-control" id="companyName" placeholder={t("Company Name")} aria-label="Select Company" >
									<option value="">Select Company</option>
									{companyList.map((v, i) => {
										return <option value={v.id}>{v.name}</option>;
									})}
								</select>
								<span className="input-error" role="alert">{error.companyName}</span>
							</div>
						</div>
						{/*[END:COMPANYNAME]*/}
					</Row>
					<Row className="mb-4">
						{/*[START:PHONE]*/}
						<div className="form-group col-sm-6 theme-signup-lastname">
							<label htmlFor="phone-number" className=" col-form-label">
								<Trans>Phone Number</Trans>
								{validation["cellPhone"] && validation["cellPhone"].mandatory ? <Required /> : ""}
							</label>
							<div className="">
								<input type="text" 
								data-test="register-phoneNumber" 
								name="cellPhone" 
								onChange={handleChange} 
								className="form-control" 
								id="phone-number" 
								placeholder={t("Phone Number")} 
								aria-label="Enter Phone Number"
								aria-autocomplete="contact-number"
								/>
								<span className="input-error" role="alert">{error.cellPhone}</span>
							</div>
						</div>
						{/*[END:PHONE]*/}
					</Row>
					<h3 align="center" className="mb-4">
						Please read & save a copy for your record.
					</h3>
					<Row>
						{/* <div className="col-sm-12">
							<FormGroup>
								<input style={{ zoom: "1.2" }} type="checkbox" id="agreewith" name="agreewith" onChange={(e) => termConditionChkHandler(e.target.checked, "agree_to_opt_in")} />{" "}
								<label htmlFor="agreewith" className="agreewith">
									{" "}
									<Trans>lang_key_by_registering</Trans>{" "}
									<a href="/termsandconditions" target="new">
										<Trans>lang_key_termsConditions</Trans>
									</a>{" "}
									<Trans>lang_key_and</Trans>{" "}
									<a href="https://privacypolicy.pg.com/en/" target="new">
										<Trans>lang_key_privacypolicy</Trans>
									</a>
									.<Required />
									<br />
									<span className="input-error">{error.agree_to_opt_in}</span>
								</label>
							</FormGroup>
						</div> */}
						<div className="col-sm-12">
							<div className="v-align-baseline form-check">
								<input className="form-check-input" checked={data.additionalInfo.agree_ackno_opt_in} type="checkbox" id="agree_ackno_opt_in" name="agree_ackno_opt_in" onChange={(e) => termConditionChkHandler(e.target.checked, "agree_ackno_opt_in")} />{" "}
								<label htmlFor="agree_ackno_opt_in" className="form-check-label">
									{" "}
									I agree and acknowledge that my company’s employees may participate in the <u>P&G Professional Partner Rewards</u> program according to the <u>P&G Professional Partner Rewards</u>
									{/* <a href="/termsandconditions" target="new">
										<Trans>lang_key_termsConditions</Trans>
									</a>{" "} */}
									, which I have reviewed.
									<Required />
									<br />
									<span className="input-error" role="alert">{error.agree_ackno_opt_in}</span>
								</label>
							</div>
						</div>
						{/* checkbox2 */}
						<div className="mt-3 col-sm-12">
							<div className="v-align-baseline form-check">
								<input className="form-check-input" type="checkbox" id="agree_to_program_scope" name="agree_to_program_scope" onChange={(e) => termConditionChkHandler(e.target.checked, "agree_to_program_scope")} />{" "}
								<label htmlFor="agree_to_program_scope" className="form-check-label">
									{" "}
									I understand the scope of the P&G Program as defined in its terms and conditions.  I agree that my company and its employees will not offer, share, pay, or otherwise provide any portion of incentives earned under the P&G Program to any other person or party in order to induce sales of P&G products.
									<Required />
									<br />
									<span className="input-error" role="alert">{error.agree_to_program_scope}</span>
								</label>
							</div>
						</div>
						{/* checkbox3 */}
						<div className="mt-3 col-sm-12">
							<div className="v-align-baseline form-check">
								<input className="form-check-input" type="checkbox" id="agree_to_my_computer" name="agree_to_my_computer" onChange={(e) => termConditionChkHandler(e.target.checked, "agree_to_my_computer")} />{" "}
								<label htmlFor="agree_to_my_computer" className="form-check-label">
									{" "}
									My company does and will fully comply with all applicable governmental, legal, regulatory and professional requirements, including but not limited to anti-corruption, anti-bribery, anti-fraud and anti-kickback laws (collectively "Laws").  In addition to any other measures necessary to comply with the Laws, my company will not offer, pay, promise to pay, or authorize the payment of money or anything of value to any officer, employee or any person act­ing in an official capacity for or on behalf of a government or an entity owned or controlled by a government (“Public Official”) in order to i) influence any act or decision of the Public Official in his or her official capacity or ii) secure any other improper business advantage in connection with the purchase and sale of P&G products.
									<Required />
									<br />
									<span className="input-error" role="alert">{error.agree_to_my_computer}</span>
								</label>
							</div>
						</div>
						{/* checkbox4 */}
						<div className="mt-3 col-sm-12">
							<div className="v-align-baseline form-check">
								<input className="form-check-input" type="checkbox" id="agree_to_strickly_limited" name="agree_to_strickly_limited" onChange={(e) => termConditionChkHandler(e.target.checked, "agree_to_strickly_limited")} />{" "}
								<label htmlFor="agree_to_strickly_limited" className="form-check-label">
									{" "}
									I understand that the P&G Program is strictly limited to defined P&G Fabric, Home and Family Care products.  To the extent my company sells to any health care professionals or institutions (including without limitation doctors, hospitals, clinics, dentists, nurses, hygienists, or any other patient care setting, whether public or private), my company will not offer, pay, promise to pay, or authorize the payment of money or anything of value to any person act­ing on behalf of an institution that participates in a state or federal health care program in order to induce the purchase of P&G products. 
									<Required />
									<br />
									<span className="input-error" role="alert">{error.agree_to_strickly_limited}</span>
								</label>
							</div>
						</div>
						{/* checkbox5 */}
						<div className="mt-3 col-sm-12">
							<div className="v-align-baseline form-check">
								<input className="form-check-input" type="checkbox" id="agree_to_in_connection" name="agree_to_in_connection" onChange={(e) => termConditionChkHandler(e.target.checked, "agree_to_in_connection")} />{" "}
								<label htmlFor="agree_to_in_connection" className="form-check-label">
									{" "}
									In connection with its purchase and sale of P&G products, my company has and will maintain (i) complete and accurate books and records in accordance with all Laws; (ii) compliance policies and procedures and internal control systems designed to prevent any violation of Laws; and (iii) appropriate training of my company’s employees and any persons acting on my company’s behalf to prevent any violation of Laws.
									<Required />
									<br />
									<span className="input-error" role="alert">{error.agree_to_in_connection}</span>
								</label>
							</div>
						</div>
						{/* checkbox6 */}
						<div className="mt-3 col-sm-12">
							<div className="v-align-baseline form-check">
								<input className="form-check-input" type="checkbox" id="agree_to_reserves_rights" name="agree_to_reserves_rights" onChange={(e) => termConditionChkHandler(e.target.checked, "agree_to_reserves_rights")} />{" "}
								<label htmlFor="agree_to_reserves_rights" className="form-check-label">
									{" "}
									I understand that P&G reserves the right to modify, suspend, or cancel the P&G Program at any time, without prior notice or compensation to participants, except as to legitimate incentives earned up to the point of modification/cancellation.  I further understand that my company may withdraw its participation from this program at any time. 
									<Required />
									<br />
									<span className="input-error" role="alert">{error.agree_to_reserves_rights}</span>
								</label>
							</div>
						</div>
						{/* checkbox7 */}
						<div className="mt-3 col-sm-12">
							<div className="v-align-baseline form-check">
								<input className="form-check-input" type="checkbox" id="agree_to_sign_aggr" name="agree_to_sign_aggr" onChange={(e) => termConditionChkHandler(e.target.checked, "agree_to_sign_aggr")} />{" "}
								<label htmlFor="agree_to_sign_aggr" className="form-check-label">
									{" "}
									I represent that I have authority to sign this agreement and acknowledgement on behalf of my company.
									<Required />
									<br />
									<span className="input-error" role="alert">{error.agree_to_sign_aggr}</span>
								</label>
							</div>
						</div>
					</Row>

					{/*[START:GOOGLECAPTCHA]*/}
					{captchaEnable ? (
						<div className="mt-3 form-group row">
							<div className="col-sm-12">
								<label htmlFor="g-recaptcha-response" className="col-sm-12 col-form-label invisible" aria-hidden="true">Google captcha</label>
								<Captcha align={"left"} reset={resetCaptchaCnt} parentCallback={setCaptchaValue} />
								<p className="input-error" style={{ marginTop: "-12px" }} role="alert">
									{" "}
									{error.captcha}
								</p>
							</div>
						</div>
					) : (
						""
					)}
					{/*[END:GOOGLECAPTCHA]*/}
					<div className="text-center">
						<button type="button" id="register-submit-btn" data-test="register-submit" onClick={onSubmit} className="btn btn-primary">
							<Trans>REGISTER</Trans>
						</button>
					</div>
				</form>
			</div>
			<Modal show={show} onHide={handleClose} backdrop="static" size="lg" keyboard={false}>
				<Modal.Body>
					<TermsAndCondition pdflink={true} />
					<div className="justify-content-center text-center mt-2">
						<button className="btn btn-modal pl-5 pr-5" onClick={handleClose}>
							I ACCEPT
						</button>
						<div className="mt-4">
							<button className="btn btn-modal" onClick={handleNotAgree}>I do not wish to participate in the P&G Professional Partner Rewards Program</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default connect(null, { signup })(IsLoadingHOC(Confirmation));
