import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Logout from "./Logout";
import { notificationGetAction } from "../redux/actions";
import { defaultOptions } from "../config";
import NotificationCenter from "./NotificationCenter";
import { useTranslation, Trans } from "react-i18next";
import { store } from "../redux/store";

const TopRight = (props) => {
	const [showNotification, setShowNotification] = useState(false);
	const { t } = useTranslation();
	const user = useSelector(({ user }) => user);
	const dispatch = useDispatch();
	let notificationList = useSelector(({ app }) => (app.notificationCenter ? app.notificationCenter : []));
	useEffect(() => {
		const interval = setInterval(() => {
			loadNotification();
		}, defaultOptions.loadNotificationInterval);
		return () => clearInterval(interval);
		//eslint-disable-next-line
	}, []);
	const loadNotification = () => {
		const accessToken = store.getState("user").user.accessToken;
		if (accessToken) {
			const notifications = store.getState("app").app.notificationCenter;
			if (notifications.length > 0) {
				dispatch(notificationGetAction(notifications[0]["time"]));
			} else {
				dispatch(notificationGetAction(""));
			}
		}
	};
	const navslide = () => {
		document.getElementById("useruldropdownmenu").classList.remove("show");
		document.getElementById("userdropdownmenu").classList.remove("show");
	};
	const [ show, setShow ] = useState(false);

	return (
		<Fragment>
			{user.accessToken ? (
				<div className="order-3 user-pos"> 
					<div className="dropdown user-menu" id="useruldropdownmenu">
						<button id="href-welcome" className="dropdown-toggle user-menu-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<div className="mr-3 text-center d-none d-sm-inline-block">
								<strong>
									{user.pointBalance} <Trans>Points</Trans>
								</strong> 
								<div>{user.name ? user.name : user.username}</div>
							</div>
							<span>
								<img className="userprofile-icon" src="/public/assets/images/profile/004-profile.png" alt="Profile" />
							</span>
						</button>
						<ul className="dropdown-menu dropdown-menu-right" id="userdropdownmenu">
							<div className="pt-2 pb-2 navbar-divide-bot text-center d-block d-md-none">
								<strong>
									{user.pointBalance} <Trans>Points</Trans>
								</strong> 
								<div>{user.name ? user.name : user.username}</div>
							</div>

							{/*[START:POINTSHISTORY]*/}
							<li className="dropdown-item mt-3">
								<Link id="href-point-history" to="/points-history" onClick={() => setShow(!show)}>
									<span>
										<img className="dd-item-icon" src="/public/assets/images/profile/006-star.png" alt={t("Points History")} />
									</span>
									{t("Points History")}
								</Link>
							</li>
							{/*[END:POINTSHISTORY]*/}

							{/*[START:MYREWARDS]*/}
							<li className="dropdown-item">
								<Link id="href-my-rewards" to="/my-rewards" onClick={navslide}>
									<span>
										<img className="dd-item-icon" src="/public/assets/images/profile/Copy of 001-dollar-symbol.png" alt={t("My Rewards")} />
									</span>
									{t("My Rewards")}
								</Link>
							</li>
							{/*[END:MYREWARDS]*/}

							{/*[START:PROFILE]*/}
							<li className="dropdown-item">
								<Link id="href-profile" to="/profile">
									<span>
										<img className="dd-item-icon" src="/public/assets/images/profile/004-profile.png" alt={t("Profile")} />
									</span>
									{t("Profile")}
								</Link>
							</li>
							{/*[END:PROFILE]*/}
							{/*[START:LOGOUT]*/}
							<li className="dropdown-item">
								<Logout />
							</li>
							{/*[END:LOGOUT]*/}
						</ul>
					</div>
				</div>
			) : (
				""
			)}
			{/* {user.accessToken ? (
				<Link id="href-notification" className=" nav-link-custom" to={"#"} data-toggle="tooltip" data-placement="bottom" title={t("Notification Center")} onClick={(e) => setShowNotification(!showNotification)}>
					<li className="nav-item nav-item-custom">
						<div className="menu-notification-icon">
							<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-bell" viewBox="0 0 16 16">
								<path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
							</svg>
							<span className="badge">{notificationList.filter((v) => !v.isRead).length}</span>
						</div>
					</li>
				</Link>
			) : (
				""
			)}
			{showNotification ? <NotificationCenter showNotification={showNotification} parentCall={setShowNotification} /> : ""} */}
		</Fragment>
	);
};

export default TopRight;
