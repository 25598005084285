import React from "react";
import RewardsList from "../../components/rewards/RewardsList";
//import CarouselCustom from "../../components/CarouselCustom";
import IsLoadingHOC from "../../components/IsLoadingHOC";
class Rewards extends React.Component {
	render() {
		return (
			<>
				<div>
					<img className="d-block w-100 banner-img desktop-hide" src="/public/assets/images/banner/Desktop 6_PGP_AssetRequest_LifestyleImages_1920x600.png" alt="" />
					<img className="d-block w-100 mobile-hide banner-img" src="/public/assets/images/banner/Mobile 5_PGP_AssetRequest_LifestyleImages_450x500.png" alt="" />
				</div>
				<RewardsList />
			</>
		);
	}
}

export default IsLoadingHOC(Rewards);
