import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import IsLoadingHOC from "./../IsLoadingHOC";
import { getMyRewardsAction } from "./../../redux/actions";
import TableReport from "./../TableReport";
const MyRewards = (props) => {
	const { t } = useTranslation();
	const [rewardList, setRewardList] = useState([]);
	const columns = [
		{ dataField: "description", text: t("Description") },
		// { dataField: "date", text: t("Date") },
		{ dataField: "points", text: t("Points") },
	];
	useEffect(() => {
		props.setLoading(true);
		getMyRewardsAction(0, 5000)
			.then((responseData) => {
				if (responseData.httpCode === 200) {
					responseData.data.content = responseData.data.content.map((v) => ({ ...v, date: new Date(v.date).toLocaleDateString() }));
					setRewardList(responseData.data.content);
				} else {
					setRewardList([]);
					console.log("responseData", responseData);
				}
				props.setLoading(false);
			})
			.catch((err) => {
				props.setLoading(false);
				setRewardList([]);
				console.log("Response Error:", JSON.stringify(err));
			});
		// eslint-disable-next-line
	}, []);

	return (
		<Container>
			<Row xs={1} md={1} lg={1}>
			<h1 className="mb-5 mt-5">{t("Manage Your Account")}</h1>
				{/* <h2 className="h2-title text-center">
					<Trans>My Rewards</Trans>
				</h2> */}
			</Row>
			<Row className="mt-3 mb-5 page-tab_nav">
				<Col className="text-left">
					<Link id="href-point-history" to="/points-history">
						{t("Points History")}
					</Link>
				</Col>
				<Col className="text-center">
					<Link id="href-my-rewards" to="/my-rewards" className="active-page">
						{t("My Rewards")}
					</Link>
				</Col>
				<Col className="text-right">
					<Link id="href-profile" to="/profile">
						{t("Profile")}
					</Link>
				</Col>
			</Row>
			<Row>
				<Col xs lg="12" className="mya_table">
					<TableReport 
					searchTextboxEnable={false} 
					keyField="eventId" 
					data={rewardList} 
					columns={columns}
					wrapperClasses="table-responsive"
					rowClasses="text-nowrap"
					  />
				</Col>
			</Row>
		</Container>
	);
};

export default IsLoadingHOC(MyRewards);
