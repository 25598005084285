import React from "react";
import { Row, Col } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

const TermsAndCondition = (props) => {
	return (
		<>
			<div className="container">
				<div className="row justify-content-md-center section-content" id="termsandcondition">
					<Col md={12}>
						<div className="text-center mt-4">
							<h4>
								<strong>Terms and Conditions for the P&G Professional Partner Rewards Program (the “P&G Program”)</strong>
							</h4>

							{props.pdflink ? (
								<p className="mb-4 mt-2">
									<i>
										Please read carefully to ensure you agree and{" "}
										<a href="/public/assets/doc/P&G-Professional-Partner-Rewards-Terms-and-Conditions.pdf" target="_blank">
											save or print a copy
										</a>{" "}
										for your records.
									</i>
								</p>
							) : (
								<p className="mb-4 mt-2">
									<i>Please read carefully to ensure you agree and save a copy for your records</i>
								</p>
							)}
						</div>
						<div className="mb-5">
							<ul className="listing-points">
								<li>
									<strong>Taxation:</strong> Redemption of awards does not reflect applicable taxes, if any. Any personal tax implications arising from the P&G Program are for the participants to assess and pay for in appropriate consultation with their personal tax advisors.
								</li>
								<li>
									<strong>No Employment:</strong> Participation in the P&G Program does not create an employment or agency relationship with P&G; does not and is not intended to affect a participant’s current employment status; and does not entitle the participant to any compensation or benefit plan or qualify a participant for any right, program, or policy from P&G or its
									subsidiaries. Participation in the P&G Program requires prior approval from a participant’s employer.
								</li>
								<li>
									<strong>Privacy:</strong> By registering, I agree that my data may be used for marketing purposes by P&G Professional and other trusted P&G brands and programs. Click to read P&G Website{" "}
									<a href="https://termsandconditions.pg.com/en-us/" target="_blank">
										<Trans>lang_key_termsConditions</Trans>
									</a>{" "}
									<Trans>lang_key_and</Trans>{" "}
									<a href="https://privacypolicy.pg.com/en/#statePrivacyNotice" target="new">
										<Trans>lang_key_privacypolicy</Trans>
									</a>
									.
								</li>
								<li>
									<p>
										<strong>Program Scope:</strong> This P&G Program scope concerns qualifying U.S. sales that P&G makes to participating private companies of certain P&G products outlined in the listed promotions (which are strictly limited to P&G Fabric, Home and Family Care products). For the avoidance of doubt, P&G will not be providing any incentives for sales of P&G
										products outside of this scope.
									</p>
									<p>
										Incentives under the P&G Program are intended for and limited to P&G Program participants only. No incentives earned or payable under the P&G Program are permitted, intended, or may be shared with any other party, including, without limitation, any party that buys products from the participant or from the participant’s employer. P&G Program participants
										agree that they will not claim or offer incentives for sales in violation of this P&G Program scope nor offer, share, pay, or otherwise provide any portion of incentives earned under the P&G Program to others in order to induce the purchase of P&G products. Without limiting the generality of the foregoing, the P&G Program does not permit or intend that any
										inducement or incentive for the purchase of P&G products be offered or given to any  Highly Restricted organization or its employees that participates in a state or federal health care program or other government reimbursement program.
									</p>
								</li>
								<li>
									<p>
										<strong>Legal Compliance and Prohibition of Bribery:</strong> P&G Program participants agree they will fully comply with all applicable governmental, legal, regulatory and professional requirements, including but not limited to anticorruption, anti-bribery, anti-fraud and anti-kickback laws (collectively "Laws").
									</p>
									<p>
										P&G Program participants further agree they will not offer, pay, promise to pay, or authorize the payment of money or anything of value to i) any officer, employee or other person acting in an official capacity for or on behalf of a government or an entity owned or controlled by a government (“Public Official”) or ii) any person acting on behalf of an
										institution that participates in a state or federal health care program (“Health Care Employee”) in order to influence any act or decision of such Health Care Employee or Public Official in his or her official capacity, induce the purchase or sale of P&G products, or secure any other improper business advantage involving P&G products.
									</p>
								</li>
								<li>
									<strong>Proof of Performance:</strong> Award redemption shall be conditioned on performance verification steps that P&G may require and update from time to time in its sole discretion. P&G also retains sole discretion to resolve any dispute as to the eligibility of a participant or a sale claimed to be qualifying. Any attempt by a participant to deliberately
									undermine the legitimate operation of the P&G Program, violate P&G Program terms and conditions, or submit false or fraudulent claims may be a violation of criminal and civil law. Should such an attempt be made, P&G reserves the right to immediately terminate the participant’s activity in the P&G Program, seek damages from any such participant to the fullest
									extent permitted by law, or take other appropriate action.
								</li>
								<li>
									<strong>Cap:</strong> Points have no monetary value. They may be redeemed for incentives at a rate to be defined by P&G from time to time in its sole discretion. The maximum value of any incentives redeemed shall be capped at $1,250 per quarter, not exceed $5,000 USD per calendar year per participant, or such other maximum amount as P&G may designate from time to time in its sole discretion.
								</li>
								<li>
									<strong>Revocation clause:</strong> P&G reserves the right to modify, suspend, or cancel the P&G Program at any time, without prior notice or compensation to participants, except as to legitimate incentives earned up to the point of modification/cancellation. All issues and questions concerning the Highly Restricted interpretation and enforceability of these
									terms and conditions shall be governed by the laws of the State of Ohio.
								</li>
							</ul>
						</div>
					</Col>
				</div>
			</div>
		</>
	);
};

export default TermsAndCondition;
