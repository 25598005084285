import React from "react";
import UploadReceiptImage from "../../components/ValidatePurchase/UploadReceiptImage";
const UploadReceipt = (props) => {
	window.location.href = "/";
	return (
		<>
			<UploadReceiptImage />
		</>
	);
};

export default UploadReceipt;
