import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Container, Row, Col, Button, FormGroup, Modal, CloseButton } from "react-bootstrap";
import IsLoadingHOC from "../IsLoadingHOC";
import { toast } from "react-toastify";
import { uploadImageAction, validateUploadReceiptAction, getBrandListAction } from "../../redux/actions";
import PreviousReceiptList from "./PreviousReceiptList";
import FileDropZone from "../FileDropZone";
import { useSelector } from "react-redux";
import Required from "../../components/Required";
import Captcha from "../CaptchaClick";
import { generatePdfFromImages } from "../../hooks/ImagesToPDF";
const UploadReceipt = (props) => {
	const { t } = useTranslation();
	const quarterDateList = [
		{ key: "2022-10-01|2022-12-31", value: "01 Oct 2022 to 31 Dec 2022" },
		{ key: "2023-01-01|2023-03-31", value: "01 Jan 2023 to 31 Mar 2023" },
	];
	const config = useSelector((state) => state.app.config);
	const [show, setShow] = useState(false);
	const [modalContentType, setModalContentType] = useState(0);
	const [formAddData, setFormAddData] = useState({ additionalInfo: { agree_to_program_scope: false, parseWithOcr: false, prevQuarterPhyCaseSale: "", curQuarterPhyCaseSale: "", curQuarterDate: "", incrPhyCaseSale: "", brand: "" } });
	let [brandList, setBrandList] = React.useState([]);
	const handleClose = () => {
		setShow(false);
		resetAllSelectionBtnClick();
	};
	const handleShow = (type) => {
		setModalContentType(type);
		setShow(true);
	};
	const loadBrandList = () => {
		props.setLoading(true);
		getBrandListAction()
			.then((response) => {
				if (response.httpCode === 200 && response.data) {
					if (response.data) {
						let dt = response.data.sort((a, b) => (a.brandName.toLowerCase() > b.brandName.toLowerCase() ? 1 : -1));
						let fData = [];
						for (let i in dt) {
							let ind = fData.findIndex((v) => v.brandName === dt[i].brandName);
							if (ind < 0) {
								fData.push(dt[i]);
							}
						}
						setBrandList(fData);
					}
				}
				props.setLoading(false);
			})
			.catch((e) => {
				props.setLoading(false);
			});
	};
	const termConditionChkHandler = (checked, name) => {
		let d = JSON.parse(JSON.stringify(formAddData));
		if (checked) {
			d.additionalInfo[name] = true;
		} else {
			d.additionalInfo[name] = false;
		}
		setFormAddData(d);
	};
	const handleChange = (e) => {
		const { name, value } = e.target;
		let d = JSON.parse(JSON.stringify(formAddData));
		d.additionalInfo[name] = value;
		let curSale = 0,
			prevSale = 0;
		if (name === "curQuarterPhyCaseSale" || name === "prevQuarterPhyCaseSale") {
			if (value !== "" && isNaN(value)) {
				return false;
			} else if (value !== "" && value.indexOf(".") > 0 && value.split(".")[1].length > 2) {
				return false;
			}
		}
		if (name === "curQuarterPhyCaseSale") {
			curSale = value === "" || value === "." ? 0 : parseFloat(value);
			prevSale = parseFloat(d.additionalInfo.prevQuarterPhyCaseSale ? d.additionalInfo.prevQuarterPhyCaseSale : 0);
			d.additionalInfo.incrPhyCaseSale = curSale - prevSale > 0 ? (curSale - prevSale).toFixed(2) : 0;
		} else if (name === "prevQuarterPhyCaseSale") {
			curSale = parseFloat(d.additionalInfo.curQuarterPhyCaseSale ? d.additionalInfo.curQuarterPhyCaseSale : 0);
			prevSale = value === "" || value === "." ? 0 : parseFloat(value);
			d.additionalInfo.incrPhyCaseSale = curSale - prevSale > 0 ? (curSale - prevSale).toFixed(2) : 0;
		}
		setFormAddData(d);
	};
	let [captchaValue, setCaptchaValue] = useState(false);
	let [resetCaptchaValue, setResetCaptchaValue] = useState(0);
	let [captchaEnable, setCaptchaEnable] = useState(false);
	let [multiReceiptUpload, setMultiReceiptUpload] = useState(false);
	let [selectedFile, setSelectedFile] = useState([]);
	const [receiptLoadCounter, setReceiptLoadCounter] = useState(1);
	let [enableUploading, setEnableUploading] = useState(true);
	React.useEffect(() => {
		if (config.receiptUploadPreValidation) {
			checkUploadEnableOrNot(false);
		}
		if (config.captchaConfig) {
			if (config.captchaConfig.uploadReceiptCaptcha) {
				setCaptchaEnable(true);
			}
			if (config.multiReceiptUpload) {
				setMultiReceiptUpload(config.multiReceiptUpload);
			}
		}
		loadBrandList();
		//eslint-disable-next-line
	}, [config]);
	const checkUploadEnableOrNot = (uploadApiCall) => {
		props.setLoading(true);
		validateUploadReceiptAction({})
			.then((responseData) => {
				props.setLoading(false);
				setReceiptLoadCounter(receiptLoadCounter + 1);
				if (responseData["httpCode"] >= 200 && responseData["httpCode"] <= 299) {
					setEnableUploading(responseData.data.valid);
					if (uploadApiCall) {
						uploadFileHandler(responseData.data.token);
					}
					return true;
				} else {
					toast.error(`${t("Error")}! ${t("You have reached the program limit for rewards")}.`);
				}
			})
			.catch((err) => {
				console.log("error", err);
				toast.error(`${t("Error")}! ${t("You have reached the program limit for rewards")}.`);
				props.setLoading(false);
			});
	};
	const uploadImage = () => {
		if (selectedFile.length === 0) {
			toast.error(`${t("Error")}! ${t("Select a invoice.")}`);
			return false;
		}
		if (!formAddData.additionalInfo.brand) {
			toast.error(`${t("Error")}! ${t("Please select Brand.")}`);
			return false;
		}
		if (!formAddData.additionalInfo.prevQuarterPhyCaseSale) {
			toast.error(`${t("Error")}! ${t("Please enter Previous Quarter Physical Case Sales.")}`);
			return false;
		}
		if (!formAddData.additionalInfo.curQuarterPhyCaseSale) {
			toast.error(`${t("Error")}! ${t("Please enter Current Quarter Physical Case Sales.")}`);
			return false;
		}
		if (!formAddData.additionalInfo.curQuarterDate) {
			toast.error(`${t("Error")}! ${t("Please select Current Quarter Date.")}`);
			return false;
		}
		if (!formAddData.additionalInfo.agree_to_program_scope) {
			toast.error(`${t("Error")}! ${t("Please select the program scope checkbox.")}`);
			return false;
		}
		if (!captchaValue && captchaEnable) {
			toast.error(`${t("Error")}! ${t("Please select captcha.")}`);
			return false;
		}
		handleShow(2);
	};
	const popupUploadBtnHandler = () => {
		if (config.receiptUploadPreValidation) {
			checkUploadEnableOrNot(true);
		} else {
			uploadFileHandler("");
		}
	};
	const uploadFileHandler = async (token) => {
		let fileData = selectedFile[0];
		if (selectedFile.length > 1) {
			fileData = await generatePdfFromImages(selectedFile);
		}
		const formData = new FormData();
		formData.append("type", "receipt");
		formData.append("image", fileData);
		let addData = JSON.parse(JSON.stringify(formAddData.additionalInfo));
		addData.prevQuarterPhyCaseSale = parseFloat(addData.prevQuarterPhyCaseSale);
		addData.curQuarterPhyCaseSale = parseFloat(addData.curQuarterPhyCaseSale);
		addData.incrPhyCaseSale = parseFloat(addData.incrPhyCaseSale);
		formData.append("additionalInfo", JSON.stringify(addData));
		if (token) {
			formData.append("token", token);
		}
		if (captchaEnable) {
			formData.append("captchaVal", captchaValue);
		}
		props.setLoading(true);
		setShow(false);
		uploadImageAction(formData)
			.then((responseData) => {
				props.setLoading(false);
				setResetCaptchaValue(resetCaptchaValue + 1);
				setReceiptLoadCounter(receiptLoadCounter + 1);
				window.scroll({
					top: 0,
					left: 0,
					behavior: "smooth",
				});
				resetAllSelectionBtnClick();
				if (responseData["httpCode"] === 200) {
					toast.success(`${t("Your sales invoice has been uploaded successfully. Your Account Executive will verify at the end of the quarter")}.`);
					setSelectedFile([]);
					return true;
				} else if (responseData["httpCode"] === 409) {
					toast.error(`${t("Error")}! This invoice has already been uploaded. Please upload another invoice.`);
					setSelectedFile([]);

					return true;
				} else {
					toast.error(`${t("Error")}! ${t("Something went wrong")}`);
					return false;
				}
			})
			.catch((err) => {
				setResetCaptchaValue(resetCaptchaValue + 1);
				setCaptchaValue(false);
				console.log("error", err);
				props.setLoading(false);
				if (typeof err === "object") {
					if (err["response"]) {
						if (err["response"].httpCode === 409) {
							//duplicate Receipt message
							toast.error(`${t("Error")}! This invoice has already been uploaded. Please upload another invoice.`);
							setSelectedFile([]);
							resetAllSelectionBtnClick();
							return false;
						} else {
							toast.error(`${t("Error")}! ${t("Something went wrong")}`);
							return false;
						}
					} else {
						toast.error(`${t("Error")}! ${t("Something went wrong")}`);
						return false;
					}
				} else {
					toast.error(`${t("Error")}! ${t("Something went wrong")}`);
					return false;
				}
			});
	};
	const resetAllSelectionBtnClick = () => {
		setShow(false);
		setSelectedFile([]);
		setResetCaptchaValue(resetCaptchaValue + 1);
		let d = JSON.parse(JSON.stringify(formAddData));
		d.additionalInfo.agree_to_program_scope = false;
		d.additionalInfo.prevQuarterPhyCaseSale = "";
		d.additionalInfo.curQuarterPhyCaseSale = "";
		d.additionalInfo.curQuarterDate = "";
		d.additionalInfo.incrPhyCaseSale = "";
		d.additionalInfo.brand = "";
		setCaptchaValue(false);
		setFormAddData(d);
	};
	return (
		<Container fluid>
			<div className="validate-purchase-div">
				<div className="row justify-content-md-center text-center section-content" id="reward-page">
					<Col md={10}>
						<h1 className="main-heading section-heading">Submit Your Proof of Sales!</h1>
						<p>Earn points per case on all cases of Bounty, Dawn or Dawn Professional, Microban Professional and Tide (Excluding Tide Professional).</p>
						<p>Points will be awarded based on full physical cases sold from October 1- December 31, 2022 that are incremental to full physical cases sold from July 1 - September 30, 2022. Your Proof of Sale may include one or multiple brands. However, a brand cannot be split across multiple files.</p>
						<p>Your online sales data upload must be complete to be approved.</p>
						<div className="f22" aria-label="Check Requirments">
							<strong>
								Please double-check requirements{" "}
								<button id="href-requirements" className="btn btn-link p-0" onClick={() => handleShow(1)}>
									<strong> here </strong>
								</button>
								.
							</strong>
						</div>
					</Col>
					<Col md={10}>
						<Row className="justify-content-md-center">
							<Col md={12} className="mt-5">
								{/* <h2 className="mb-4 d-none" aria-hidden="true">Have a sale already?</h2> */}
								<h2 className="mb-4">Step 1: Upload Proof of Sale</h2>
								<p>
									Click on the box to upload PDF or XLS as proof of sale. <br />
									Note: Size of the invoice must be less than 5 MB.
									{/* Note: Multiple files can be uploaded. Uploaded files must be the same format. */}
								</p>
								{/* <p><small>*multiple files can be uploaded at once</small></p> */}
								<div className="text-center my-4">
									<Row className="justify-content-md-center">
										<Col md="12 px-0 px-md-3">
											<PreviousReceiptList reloadCounter={receiptLoadCounter} className="row" />
										</Col>
									</Row>
									<Row className="mb-3">
										<FileDropZone multiReceiptUpload={multiReceiptUpload} enableUploading={enableUploading} parentCallBack={setSelectedFile} selectedFile={selectedFile} />
									</Row>
								</div>
							</Col>
							<Col md={12} className="my-3">
								<h2 className="mb-3">Step 2: Add Sale Details</h2>
								<div className="row justify-content-center">
									<div className="col-md-9 text-left">
										{/*[START:BRAND]*/}
										<div className="form-group">
											<label htmlFor="brand" className="col-form-label">
												Brand
												<Required />
											</label>
											<div className="">
												<select data-test="" value={formAddData.additionalInfo.brand} name="brand" className="form-control" id="brand" onChange={handleChange} placeholder={t("Select Your Brand")}>
													<option value=""></option>
													{brandList.map((v, i) => {
														return <option value={v.brandName}>{v.brandName} </option>;
													})}
												</select>
												{/* <span className="input-error">{error.SelectBrand}</span> */}
											</div>
										</div>
										<div className="form-group">
											<label htmlFor="prevQuarterPhyCaseSale" className="col-form-label">
												Previous Quarter Physical Case Sales
												<Required />
											</label>
											<div className="">
												<input type="text" name="prevQuarterPhyCaseSale" value={formAddData.additionalInfo.prevQuarterPhyCaseSale} onChange={handleChange} className="form-control" id="prevQuarterPhyCaseSale" aria-label="Enter last quarter sale" />
												{/* <span className="input-error" role="alert">{error.previous-quarter-sale}</span> */}
											</div>
										</div>
										<div className="form-group">
											<label htmlFor="curQuarterPhyCaseSale" className="col-form-label">
												Current Quarter Physical Case Sales
												<Required />
											</label>
											<div className="">
												<input type="text" name="curQuarterPhyCaseSale" onChange={handleChange} className="form-control" id="curQuarterPhyCaseSale" aria-label="Enter current quarter sale" value={formAddData.additionalInfo.curQuarterPhyCaseSale} />
												{/* <span className="input-error" role="alert">{error.current-quarter-sale}</span> */}
											</div>
										</div>
										<div className="form-group">
											<label htmlFor="curQuarterDate" className="col-form-label">
												Current Quarter Date
												<Required />
											</label>
											<div className="">
												<select data-test="" value={formAddData.additionalInfo.curQuarterDate} name="curQuarterDate" className="form-control" id="curQuarterDate" onChange={handleChange}>
													<option value=""></option>
													{quarterDateList.map((v, i) => {
														return <option value={v.key}>{v.value} </option>;
													})}
												</select>
											</div>
										</div>
										<div className="form-group">
											<label htmlFor="incremental-sale" className="col-form-label">
												Incremental Physical Case Sales <Required />
											</label>
											<div className="">
												<input type="text" name="incremental-sale" className="form-control" id="incremental-sale" aria-label="Enter incremental sale" value={formAddData.additionalInfo.incrPhyCaseSale} />
											</div>
										</div>
										{/*[END:INCREMENTALSALE]*/}
									</div>
								</div>
							</Col>

							<Col md={12} className="my-3">
								<h2 className="mb-3">Step 3: Submit!</h2>
								<div className="">
									<div className="v-align-baseline form-check">
										<input className="form-check-input" checked={formAddData.additionalInfo.agree_to_program_scope} type="checkbox" id="agree_to_program_scope" name="agree_to_program_scope" onChange={(e) => termConditionChkHandler(e.target.checked, "agree_to_program_scope")} />{" "}
										<label htmlFor="agree_to_program_scope" className="form-check-label text-left">
											I understand that my participation in the P&G Professional Partner Rewards Program is subject to its terms and conditions, which may be updated from time to time.  I confirm that this submission complies with the current terms and conditions posted <a href="/termsandconditions" target="_blank">here</a>, which I have reviewed and accept.
											<Required />
										</label>
										{/* <div className="input-error">{error.termCondition}</div> */}
									</div>
									<div>
										{captchaEnable ? (
											<Row md={1}>
												<Col>
													<label htmlFor="g-recaptcha-response" className="col-sm-12 col-form-label d-none" aria-hidden="true">
														Google captcha
													</label>
													<Captcha reset={resetCaptchaValue} parentCallback={setCaptchaValue} />
												</Col>
											</Row>
										) : (
											""
										)}
									</div>
								</div>

								<div className="row justify-content-center">
									<Button type="button" onClick={uploadImage} className="btn btn-primary" id="upload-btn">
										<Trans>Submit</Trans>
									</Button>
								</div>
							</Col>
						</Row>
					</Col>
				</div>
			</div>
			<Modal show={show}>
				<Modal.Body>
					<CloseButton onClick={handleClose} />
					{modalContentType === 1 ? (
						<div id="sales-requirment-points">
							<div className="modal-heading mb-3 text-center">Proof of Sale Requirements</div>
							<div>
								<ul className="checkmark">
									<li>Your online registration form is complete with the required information.</li>
									<li>Your submitted proof of sale data show both this and last quarters’ sales for the participating brand.</li>
									<li>Your data must show that your sales were made between October 1 - December 31, 2022, 11:59:59 PM EST.</li>
									<li>Your data must be clear and legible and include your name, the description of the brand/sku sold, the Case UPC and must be in full Case quantities.</li>
									<li>Your data must not have been previously submitted by you or any other individual.</li>
									{/* <li>
										Per the{" "}
										<a href="https://termsandconditions.pg.com/en-us/" target="new">
											Terms and Conditions
										</a>
										, sales to healthcare, government or education end users are excluded.
									</li> */}
									<li>Acceptable document types are PDF and XLS.</li>
								</ul>
							</div>
						</div>
					) : (
						""
					)}
					{modalContentType === 2 ? (
						<div id="data-submittion" className="text-center">
							<div>
								<p>
									<strong>Are you sure?</strong>
								</p>
								<p>You can only submit once per brand per quarter, so make sure your sales are final!</p>
							</div>
							<div>
								<button type="button" onClick={popupUploadBtnHandler} className="btn btn-modal" id="upload-btn">
									YES, SUBMIT
								</button>
							</div>
							<div className="mt-3">
								<button type="button" onClick={resetAllSelectionBtnClick} className="btn btn-modal" id="reset-btn">
									NO, TAKE ME BACK
								</button>
							</div>
						</div>
					) : (
						""
					)}
				</Modal.Body>
			</Modal>
		</Container>
	);
};

export default IsLoadingHOC(UploadReceipt);
