import React, { useState, useEffect } from "react";
import { Row, FormGroup, Modal } from "react-bootstrap";
// eslint-disable-next-line
import { signup, login, getLockupAction, preRegistrationAction } from "../../redux/actions";
import { connect } from "react-redux";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import GooglePlaces from "../../components/GooglePlaces";
//import Calendar from "react-calendar";
import Required from "../../components/Required";
import Captcha from "../../components/CaptchaClick";
import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import InputMask from "react-input-mask";
import { propTypes } from "react-bootstrap/esm/Image";
import TermsAndCondition from "../Static/TermsAndCondition";
var validator = require("validator");
const Register = ({ signup, setLoading, login }) => {
	const { t } = useTranslation();
	const confige = useSelector((state) => state.app.config);
	const history = useHistory();
	const [place, setPlace] = useState({});
	const query = new URLSearchParams(window.location.search);
	const referralid = query.get("refCode") ? query.get("refCode") : "";
	const source = query.get("src") ? query.get("src") : "";
	const promoCode = query.get("promoCode") ? query.get("promoCode") : "";
	//	<Modal show={showAgeGate} onHide={handleCloseAgeGate} backdrop="static" keyboard={false}>
	const [showAgeGate, setShowAgeGate] = useState(false);
	const handleCloseAgeGate = () => {
		setShow(false);
		window.location.href = "/";
	};
	let [companyList, setCompanyList] = useState([]);
	let [captchaValue, setCaptchaValue] = useState(false);
	let [captchaEnable, setCaptchaEnable] = useState(false);
	const [resetCaptchaCnt, setResetCaptchaCnt] = useState(0);
	const [show, setShow] = useState(false);
	const handleClose = () => {
		setShow(false);
		let d = JSON.parse(JSON.stringify(data));
		d.additionalInfo["agree_to_png_term"] = true;
		setData(d);
	};
	const handleNotAgree = () => {
		setShow(false);
	};
	const handleShow = () => setShow(true);

	const termConditionChkHandler = (checked, name) => {
		let errorArray = JSON.parse(JSON.stringify(error));
		let d = JSON.parse(JSON.stringify(data));
		if (checked) {
			if (name === "agree_to_png_term") {
				handleShow();
				errorArray[name] = t("");
			} else {
				errorArray[name] = t("");
				d.additionalInfo[name] = true;
			}
		} else {
			d.additionalInfo[name] = false;
			errorArray[name] = t("Please review the above and indicate your agreement if you wish to proceed.");
		}
		setError(errorArray);
		setData(d);
	};

	// const termConditionChkHandler = (checked, name) => {
	// 	let errorArray = JSON.parse(JSON.stringify(error));
	// 	let d = JSON.parse(JSON.stringify(data));
	// 	if (checked) {
	// 		errorArray[name] = t("");
	// 		d.additionalInfo[name] = true;
	// 	} else {
	// 		d.additionalInfo[name] = false;
	// 		errorArray[name] = t("Please review the above and indicate your agreement if you wish to proceed.");
	// 	}
	// 	setError(errorArray);
	// 	setData(d);
	// };
	const errorMessages = {
		firstName: { reqMsg: "Please enter your first name.", invalidMsg: "First Name must be  maximum 254  characters long and must contain alphabets and special characters only." },
		lastName: { reqMsg: "Please enter your last name.", invalidMsg: "Last Name must be 254 characters long and must contain alphabets and special characters only." },
		email: { reqMsg: "Please enter a valid email address.", invalidMsg: "Please enter a valid email address." },
		confirmEmail: { reqMsg: "Please enter a valid email address.", invalidMsg: "Please enter a valid email address." },
		city: { reqMsg: "Please enter your city.", invalidMsg: "Please enter a valid city name." },
		address: { reqMsg: "Please enter your address.", invalidMsg: "Please enter a valid address." },
		suite: { reqMsg: "Please enter your SUITE/APT. NO.", invalidMsg: "Please enter a valid SUITE/APT. NO." },
		state: { reqMsg: "Please enter your state.", invalidMsg: "Please enter a valid state." },
		zip: { reqMsg: "Please enter your zip code.", invalidMsg: "Please enter a valid zip code." },
		cellPhone: { reqMsg: "Please enter valid phone number.", invalidMsg: "Please enter valid phone number." },
		birth: { reqMsg: "Please enter your valid DOB.", invalidMsg: "Please enter your valid DOB." },
		companyName: { reqMsg: "Please select Company.", invalidMsg: "Please select Company." },
		password: { reqMsg: "Password length should be 8-15 characters, must contain at least 1 uppercase, 1 lowercase, 1 special symbol and 1 number.", invalidMsg: "Password length should be 8-15 characters, must contain at least 1 uppercase, 1 lowercase, 1 special symbol and 1 number." },
		passwordConfirm: { reqMsg: "Confirm password length should be 8-15 characters, must contain at least 1 uppercase, 1 lowercase, 1 special symbol and 1 number.", invalidMsg: "Confirm password length should be 8-15 characters, must contain at least 1 uppercase, 1 lowercase, 1 special symbol and 1 number." },
		socialSecurityNumber: { reqMsg: "Please enter a valid social security number.", invalidMsg: "Please enter a valid social security number." },
	};

	/*[START:INITIALIZE_DECLARATION]*/
	const [data, setData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		confirmEmail: "",
		birth: "",
		cellPhone: "",
		address: "",
		state: "",
		suite: "",
		city: "",
		zip: "",
		companyName: "",
		password: "",
		passwordConfirm: "",
		socialSecurityNumber: "",
		latitude: "",
		longitude: "",
		additionalInfo: { visitorId: "", requestId: "", agree_to_opt_in: false, agree_to_png_term: false, agree_to_program_scope: false, agree_to_png_policy: false },
	});
	/*[END:INITIALIZE_DECLARATION]*/
	const [underAge, setUnderAge] = useState(false);
	const [validation, setValidation] = useState({});
	const [error, setError] = useState({ firstName: "", email: "", confirmEmail: "", cellPhone: "", lastName: "", address: "", state: "", suite: "", zip: "", city: "", passwordConfirm: "", password: "", socialSecurityNumber: "", captcha: "", agree_to_opt_in: "", agree_to_png_term: "", agree_to_program_scope: "", agree_to_png_policy: "" });
	useEffect(() => {
		let items = {};
		let oldState = { ...data, password: "", passwordConfirm: "" };
		setData(oldState);
		if (confige.uiConfig) {
			confige.uiConfig.userFields.forEach((element) => {
				if (["address", "city", "zip", "state", "birth", "cellPhone", "socialSecurityNumber"].indexOf(element.name) >= 0) {
					element.validation.mandatory = true;
				}
				items[element.name] = element.validation;
				if (element.name === "email") {
					items["confirmEmail"] = element.validation;
				}
			});
			confige.uiConfig.passwordRule.mandatory = true;
			confige.uiConfig.passwordRule.hasNumeric = true;
			items.password = confige.uiConfig.passwordRule;
			items.passwordConfirm = confige.uiConfig.passwordRule;
			if (confige.fingerprinting) {
				if (confige.fingerprinting.enable) {
					const fpPromise = FingerprintJS.load({ apiKey: confige.fingerprinting.clientKey });
					// Get the visitor identifier when you need it.
					fpPromise
						.then((fp) => fp.get())
						.then((result) => {
							let fdata = JSON.parse(JSON.stringify(data));
							fdata.additionalInfo.visitorId = result.visitorId;
							fdata.additionalInfo.requestId = result.requestId;
							setData(fdata);
						});
				}
			}
		}
		if (confige.captchaConfig) {
			if (confige.captchaConfig.registrationCaptcha) {
				setCaptchaEnable(true);
			}
		}
		setValidation(items);
		// eslint-disable-next-line
	}, [confige, confige.uiConfig]);
	const handleChange = (e) => {
		if (validation.password) {
			errorMessages.password.invalidMsg = `Password length should be ${validation.password.minLength}-${validation.password.maxLength} characters, must contain at least 1 uppercase, 1 lowercase, 1 special symbol and 1 number.`;
			errorMessages.password.reqMsg = errorMessages.password.invalidMsg;
			errorMessages.passwordConfirm.invalidMsg = `Confirm password length should be ${validation.password.minLength}-${validation.password.maxLength} characters, must contain at least 1 uppercase, 1 lowercase, 1 special symbol and 1 number.`;
			errorMessages.passwordConfirm.reqMsg = errorMessages.passwordConfirm.invalidMsg;
		}
		const { name, value } = e.target;
		let errorMessage = "";
		let validationArray = validation[name];
		let check = false;
		if (name === "agree_to_opt") {
			let d = JSON.parse(JSON.stringify(data));
			d.additionalInfo.agree_to_png_term = e.target.checked;
			setData(d);
			return true;
		}
		for (let key in validationArray) {
			if (key === "minLength") {
				if (value.length < validationArray[key] && value.length !== 0) {
					errorMessage = errorMessages[name]
						? errorMessages[name].invalidMsg
						: t("Should not be less then", {
								count: validationArray[key],
						  });
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "maxLength") {
				if (value.length > validationArray[key]) {
					errorMessage = errorMessages[name]
						? errorMessages[name].invalidMsg
						: t("Should not be greater then", {
								count: validationArray[key],
						  });
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "mandatory" && validationArray[key]) {
				if (!value.length) {
					errorMessage = errorMessages[name] ? errorMessages[name].reqMsg : t("Should not be empty");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "hasNumeric" && validationArray[key] && value.search(/[0-9]/) < 0) {
				errorMessage = t("Should be contain number");
				if (name === "password" || name === "passwordConfirm") {
					errorMessage = errorMessages[name].invalidMsg;
				}
				setError({ ...error, [name]: errorMessage });
				check = true;
			}
			if (key === "hasAlpha" && validationArray[key] && value.search(/[A-Z]/) < 0 && value.search(/[a-z]/) < 0) {
				errorMessage = t("Should be contain alphabets");
				if (name === "password" || name === "passwordConfirm") {
					errorMessage = errorMessages[name].invalidMsg;
				}
				setError({ ...error, [name]: errorMessage });
				check = true;
			}
			if (key === "email" && validationArray[key]) {
				if (!validator.isEmail(value)) {
					errorMessage = errorMessages.email ? errorMessages.email.invalidMsg : t("Please enter a valid email");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && !validationArray[key] && value.search(/[0-9]/) >= 0) {
				errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("Should not be contain number");
				setError({ ...error, [name]: errorMessage });
				check = true;
			} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && validationArray[key] && value.search(/[0-9]/) < 0) {
				errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("Should be contain number");
				setError({ ...error, [name]: errorMessage });
				check = true;
			}
			if (key === "upperCaseReq" && validationArray[key]) {
				if (value.search(/[A-Z]/) < 0 && value.length !== 0) {
					errorMessage = t("Should have atleast one Upper Case latter");
					if (name === "password" || name === "passwordConfirm") {
						errorMessage = errorMessages[name].invalidMsg;
					}
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "lowerCaseReq" && validationArray[key]) {
				if (value.search(/[a-z]/) < 0 && value.length !== 0) {
					errorMessage = t("Should have atleast one Lower Case latter");
					if (name === "password" || name === "passwordConfirm") {
						errorMessage = errorMessages[name].invalidMsg;
					}
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "specialCharReq" && validationArray[key]) {
				var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
				if (!format.test(value) && value.length !== 0) {
					errorMessage = t("Should have atleast one special letter");
					if (name === "password" || name === "passwordConfirm") {
						errorMessage = errorMessages[name].invalidMsg;
					}
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}

			// if (name === "passwordConfirm") {
			// 	if (data.password !== value) {
			// 		setError({
			// 			...error,
			// 			passwordConfirm: t("Password and confirm password should be same"),
			// 		});
			// 	}
			// }
		}

		if (!check) {
			setError({ ...error, [name]: "" });
		}
		setData({ ...data, [name]: value });
	};
	useEffect(() => {
		let errorArray = JSON.parse(JSON.stringify(error));
		if (captchaValue) {
			errorArray["captcha"] = "";
		}
		setError(errorArray);
	}, [captchaValue]);
	useEffect(() => {
		if (localStorage.getItem("allowReg")) {
			let regDataArr = window.atob(localStorage.getItem("allowReg")).split("|");
			if (parseInt(regDataArr[1]) === 1) {
				const newData = { ...data };
				newData.birth = regDataArr[0];
				setData(newData);
				setUnderAge(true);
			}
		}
	}, [localStorage.getItem("allowReg")]);
	const onSubmit = async () => {
		let errorArray = {};
		if (validation.password) {
			errorMessages.password.invalidMsg = `Password length should be ${validation.password.minLength}-${validation.password.maxLength} characters, must contain at least 1 uppercase, 1 lowercase, 1 special symbol and 1 number.`;
			errorMessages.password.reqMsg = errorMessages.password.invalidMsg;
			errorMessages.passwordConfirm.invalidMsg = `Confirm password length should be ${validation.password.minLength}-${validation.password.maxLength} characters, must contain at least 1 uppercase, 1 lowercase, 1 special symbol and 1 number.`;
			errorMessages.passwordConfirm.reqMsg = errorMessages.passwordConfirm.invalidMsg;
		}
		if (data.birth.length !== 0) {
			if (isValidDate(data.birth)) {
				let dt = new Date("2006-08-31");
				if (dt < new Date(data.birth)) {
					setShowAgeGate(true);
					localStorage.setItem("allowReg", window.btoa(`${data.birth}|1`));
					return false;
				}
			}
		}
		for (let name in data) {
			let value = data[name];
			let validationArray = validation[name];
			let errorMessage = "";
			if (name === "birth" && isNaN(new Date(value)) && value.length !== 0) {
				if (!isValidDate(value)) {
					errorMessage = "Enter a valid date in MM/DD/YYYY format.";
				} else {
					errorMessage = errorMessages.birth ? errorMessages.birth.invalidMsg : `${t("Invalid")} ${t("Birth Date")}`;
				}
			} else if (name === "birth") {
				errorMessage = "";
			}
			if (name === "birth" && isValidDate(value)) {
				let dt = new Date("2006-08-31");
				if (dt < new Date(data.birth)) {
					errorMessage = "Age must be 16 years or more on 31 Aug 2022, to register with this offer.";
				}
			}
			for (let key in validationArray) {
				if (key === "email" && validationArray[key] && !validator.isEmail(value)) {
					errorMessage = errorMessages.email ? errorMessages.email.invalidMsg : t("Please enter a valid email.");
				} else if (key === "minLength" && value.length < validationArray[key] && value.length !== 0) {
					errorMessage = errorMessages[name]
						? errorMessages[name].invalidMsg
						: t("Should not be less then", {
								count: validationArray[key],
						  });
				} else if (key === "maxLength" && value.length > validationArray[key]) {
					errorMessage = errorMessages[name]
						? errorMessages[name].invalidMsg
						: t("Should not be greater then", {
								count: validationArray[key],
						  });
				} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && !validationArray[key] && value.search(/[0-9]/) >= 0) {
					errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("Should not be contain number");
				} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && validationArray[key] && value.search(/[0-9]/) <= 0) {
					errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("Should be contain number");
				} else if (key === "mandatory" && validationArray[key] && !value.length) {
					errorMessage = errorMessages[name] ? errorMessages[name].reqMsg : t("Should not be empty");
				} else if (key === "upperCaseReq" && value.search(/[A-Z]/) < 0 && value.length !== 0) {
					errorMessage = t("Should have atleast one Upper Case latter");
					if (name === "password" || name === "passwordConfirm") {
						errorMessage = errorMessages[name].invalidMsg;
					}
				} else if (key === "lowerCaseReq" && value.search(/[a-z]/) < 0 && value.length !== 0) {
					errorMessage = t("Should have atleast one Lower Case latter");
					if (name === "password" || name === "passwordConfirm") {
						errorMessage = errorMessages[name].invalidMsg;
					}
				} else if (key === "hasNumeric" && validationArray[key] && value.search(/[0-9]/) < 0) {
					errorMessage = t("Should be contain number");
					if (name === "password" || name === "passwordConfirm") {
						errorMessage = errorMessages[name].invalidMsg;
					}
				} else if (key === "hasAlpha" && validationArray[key] && value.search(/[A-Z]/) < 0 && value.search(/[a-z]/) < 0) {
					errorMessage = t("Should be contain alphabets");
					if (name === "password" || name === "passwordConfirm") {
						errorMessage = errorMessages[name].invalidMsg;
					}
				} else if (key === "specialCharReq" && value.length !== 0) {
					var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
					if (!format.test(value)) {
						errorMessage = t("Should have atleast one special letter");
						if (name === "password" || name === "passwordConfirm") {
							errorMessage = errorMessages[name].invalidMsg;
						}
					}
				}
			}
			errorArray[name] = errorMessage;
			if (name === "password" || name === "passwordConfirm") {
				if (data["password"] !== data["passwordConfirm"]) {
					errorMessage = t("Password and confirm password should be same.");
				}
				errorArray["passwordConfirm"] = errorMessage;
			}
			if (name === "confirmEmail") {
				if (data["email"] !== data["confirmEmail"]) {
					errorMessage = t("Email and confirm email should be same.");
				}
				errorArray["confirmEmail"] = errorMessage;
			}
		}
		if (!captchaValue && captchaEnable) {
			errorArray["captcha"] = t("Please select captcha.");
		}
		// if (!data.additionalInfo.agree_to_opt_in) {
		// 	errorArray.agree_to_opt_in = t("Please review the above and indicate your agreement if you wish to proceed.");
		// }
		if (!data.additionalInfo.agree_to_png_term) {
			errorArray.agree_to_png_term = t("Please review the above and indicate your agreement if you wish to proceed.");
		}
		// if (!data.additionalInfo.agree_to_program_scope) {
		// 	errorArray.agree_to_program_scope = t("Please review the above and indicate your agreement if you wish to proceed.");
		// }
		// if (!data.additionalInfo.agree_to_png_policy) {
		// 	errorArray.agree_to_png_policy = t("Please review the above and indicate your agreement if you wish to proceed.");
		// }
		setError(errorArray);
		let check = false;
		for (let key in errorArray) {
			if (errorArray[key]) {
				check = true;
			}
		}
		if (!captchaValue && captchaEnable) {
			return false;
		}

		const newData = { ...data };
		if (captchaValue !== "none") {
			newData["captchaVal"] = captchaValue;
		}
		delete newData.passwordConfirm;
		if (!check) {
			if (newData.birth) {
				let datevalue = new Date(newData.birth);
				const dd = String(datevalue.getDate()).padStart(2, "0");
				const mm = String(datevalue.getMonth() + 1).padStart(2, "0");
				const yyyy = datevalue.getFullYear();
				const formatedDate = yyyy + "-" + mm + "-" + dd;
				newData.birth = formatedDate;
			}
			if (!newData.hasOwnProperty("additionalInfo")) {
				newData["additionalInfo"] = {};
			}
			if (promoCode) {
				newData["additionalInfo"]["promoCode"] = promoCode;
			}
			if (source) {
				newData["additionalInfo"]["source"] = source;
			}
			setLoading(true);
			try {
				let signupCall = true;
				if (confige.usePreRegistration) {
					signupCall = false;
					let preRegistrationResp = await preRegistrationAction(newData);
					if (preRegistrationResp.data) {
						if (preRegistrationResp.data.valid) {
							signupCall = true;
							newData.additionalInfo.token = preRegistrationResp.data.token;
						} else {
							toast.error(preRegistrationResp.data.errorReason);
							setLoading(false);
							setResetCaptchaCnt(resetCaptchaCnt + 1);
							setCaptchaValue(false);
						}
					} else {
						toast.error(t("Something went wrong"));
						setLoading(false);
						setResetCaptchaCnt(resetCaptchaCnt + 1);
						setCaptchaValue(false);
					}
				}
				if (signupCall) {
					let signupResp = await signup(newData, referralid);
					let loginPayload = { username: newData.email, password: newData.password, skipCaptchaVal: true, visitorId: newData.additionalInfo.visitorId, requestId: newData.additionalInfo.requestId };
					if (captchaValue !== "none") {
						loginPayload["captchaVal"] = captchaValue;
					}
					login(loginPayload)
						.then((resp) => {
							setResetCaptchaCnt(resetCaptchaCnt + 1);
							setCaptchaValue(false);
							toast.success("Thank you! You have been registered for the program.");
							history.push("/");
							window.scroll({
								top: 0,
								left: 100,
								behavior: "smooth",
							});
							setLoading(false);
						})
						.catch((err) => {
							setResetCaptchaCnt(resetCaptchaCnt + 1);
							setCaptchaValue(false);
							toast.success(signupResp);
							history.push("/login");
							window.scroll({
								top: 0,
								left: 100,
								behavior: "smooth",
							});
							setLoading(false);
						});
				}
			} catch (error) {
				setResetCaptchaCnt(resetCaptchaCnt + 1);
				setCaptchaValue(false);
				console.log("errror", error);
				setLoading(false);
				if (Array.isArray(error)) {
					error.map((item) => {
						toast.error(item.message);
					});
				} else {
					toast.error(t("Something went wrong"));
				}
			}
		}
	};
	const handleDate = (value) => {
		setData({ ...data, birth: value });
	};
	useEffect(() => {
		if (place.hasOwnProperty("address")) {
			let err = { ...error };
			let curStateData = Object.assign({}, data);
			if (curStateData.hasOwnProperty("address")) {
				curStateData.address = place.address;
				err.address = "";
			}
			if (curStateData.hasOwnProperty("state")) {
				curStateData.state = place.state;
				err.state = "";
			}
			if (curStateData.hasOwnProperty("city")) {
				curStateData.city = place.city;
				err.city = "";
			}
			if (curStateData.hasOwnProperty("suite")) {
				curStateData.suite = place.street;
				err.suite = "";
			}
			if (curStateData.hasOwnProperty("zip")) {
				curStateData.zip = place.zip;
				err.zip = "";
			}
			if (curStateData.hasOwnProperty("latitude")) {
				curStateData.latitude = place.latitude;
			}
			if (curStateData.hasOwnProperty("longitude")) {
				curStateData.longitude = place.longitude;
			}
			setData(curStateData);
			setError(err);
		}
		// eslint-disable-next-line
	}, [place]);
	const googleSelectedAddressHandler = (place) => {
		setPlace(place);
	};
	useEffect(() => {
		loadCompanyList();
	}, []);
	const loadCompanyList = () => {
		let requestData = { lookups: ["COMPANY_LIST"] };
		setLoading(true);
		getLockupAction(requestData)
			.then((response) => {
				if (response.httpCode === 200 && response.data) {
					if (response.data.content) {
						let comListInd = response.data.content.findIndex((v) => v.name === "COMPANY_LIST");
						let comList = [];
						if (comListInd >= 0) {
							comList = response.data.content[comListInd].data;
							let dt = response.data.content[comListInd].data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
							setCompanyList(dt);
						}
					}
				}
				setLoading(false);
			})
			.catch((e) => {
				setLoading(false);
			});
	};
	const isValidDate = (date) => {
		// eslint-disable-next-line
		const matches = /^(\d{1,2})[/\/](\d{1,2})[/\/](\d{4})$/.exec(date);
		if (matches === null) {
			return false;
		}
		const [_, m, d, y] = matches;
		const composedDate = new Date(+y, +m - 1, +d);
		return composedDate.getDate() === +d && composedDate.getMonth() === +m - 1 && composedDate.getFullYear() === +y;
	};
	const formatChars = {
		"1": "[12]",
		"0": "[0123456789]",
	};

	const tooltip = <Tooltip id="tooltip">Please type in your mailing address and select the proper address from the dropdown.</Tooltip>;
	const ssnTooltip = <Tooltip id="tooltip">This is required for 1099 filings in the USA if you earn $600 or more in a calendar year.</Tooltip>;
	return (
		<div className="register-page">
			<div className="col-sm-10">
				<form role="form" aria-label="Registration">
					<h1 className="main-heading">{t("Register Now")}</h1>
					<div className="text-center text-center mb-5">
						<Trans>lang_key_alreadyRegistered</Trans>? &nbsp;
						<a href="/login" id="login-href" aria-label="Click here">
							<Trans>click here</Trans>
						</a>
						&nbsp; <Trans>lang_key_toLogin</Trans>
					</div>
					<Row>
						{/*[START:FIRSTNAME]*/}
						<div className="form-group col-sm-6 theme-signup-firstname">
							<label htmlFor="first-name" className="col-form-label">
								<Trans>lang_key_fname</Trans>
								{validation["firstName"] && validation["firstName"].mandatory ? <Required /> : ""}
							</label>
							<div className="">
								<input
									type="text"
									data-test="register-firstName"
									name="firstName"
									onChange={handleChange}
									className="form-control"
									id="first-name"
									aria-label="Enter Your Name"
									aria-autocomplete="name"
									// placeholder={t("lang_key_fname")}
								/>
								<span className="input-error" role="alert">
									{error.firstName}
								</span>
							</div>
						</div>
						{/*[END:FIRSTNAME]*/}

						{/*[START:LASTNAME]*/}
						<div className="form-group col-sm-6 theme-signup-lastname">
							<label htmlFor="last-name" className=" col-form-label">
								<Trans>lang_key_lname</Trans>
								{validation["lastName"] && validation["lastName"].mandatory ? <Required /> : ""}
							</label>
							<div className="">
								<input
									type="text"
									data-test="register-lasttName"
									name="lastName"
									onChange={handleChange}
									className="form-control"
									id="last-name"
									aria-label="Your Family Name"
									aria-autocomplete="family-name"
									// placeholder={t("lang_key_lname")}
								/>
								<span className="input-error" role="alert">
									{error.lastName}
								</span>
							</div>
						</div>
						{/*[END:LASTNAME]*/}
					</Row>
					<Row>
						{/*[START:EMAIL]*/}
						<div className="form-group col-sm-6 theme-signup-email">
							<label htmlFor="email" className="col-form-label">
								<Trans>lang_key_email</Trans>
								{validation["email"] && validation["email"].mandatory ? <Required /> : ""}
							</label>
							<div className="">
								<input type="email" data-test="register-email" name="email" onChange={handleChange} className="form-control show-placegholder" id="email" placeholder="Please use your work email" aria-label="Your Email" aria-autocomplete="email" />
								<span className="input-error" role="alert">
									{error.email}
								</span>
							</div>
						</div>
						{/*[END:EMAIL]*/}
						{/*[START:EMAIL CONFIRM]*/}
						<div className="form-group col-sm-6 theme-signup-email">
							<label htmlFor="email-confirm" className="col-form-label">
								<Trans>lang_key_email_confirm</Trans>
								{validation["confirmEmail"] && validation["confirmEmail"].mandatory ? <Required /> : ""}
							</label>
							<div className="">
								<input type="email" data-test="register-email" name="confirmEmail" onChange={handleChange} className="form-control show-placegholder" id="email-confirm" placeholder="Please use your work email" aria-label="Confirm Your Email" />
								<span className="input-error" role="alert">
									{error.confirmEmail}
								</span>
							</div>
						</div>
						{/*[END:EMAIL CONFIRM]*/}
					</Row>
					<Row>
						{/*[START:PASSWORD]*/}
						<div className="form-group col-sm-6 theme-signup-password">
							<label htmlFor="password" className=" col-form-label">
								<Trans>password</Trans>
								{validation["password"] && validation["password"].mandatory ? <Required /> : ""}
							</label>
							<div className="">
								<input
									type="password"
									data-test="register-password"
									name="password"
									onChange={handleChange}
									className="form-control"
									id="password"
									aria-label="Create Password"
									// placeholder={t("password")}
								/>
								<span className="input-error" role="alert">
									{error.password}
								</span>
							</div>
						</div>
						<div className="form-group col-sm-6 theme-signup-password">
							<label htmlFor="password-confirm" className=" col-form-label">
								<Trans>confirm password</Trans>
								{validation["passwordConfirm"] && validation["passwordConfirm"].mandatory ? <Required /> : ""}
							</label>
							<div className="">
								<input
									type="password"
									data-test="register-password-confirm"
									name="passwordConfirm"
									onChange={handleChange}
									className="form-control"
									id="password-confirm"
									aria-label="Re-enter password"
									// placeholder={t("confirm password")}
								/>
								<span className="input-error" role="alert">
									{error.passwordConfirm}
								</span>
							</div>
						</div>
						{/*[END:PASSWORD]*/}
					</Row>
					<Row>
						{/*[START:COMPANYNAME]*/}
						<div className="form-group col-sm-12 theme-signup-companyName">
							<label htmlFor="companyName" className="col-form-label">
								<Trans>Company Name</Trans>
								{validation["companyName"] && validation["companyName"].mandatory ? <Required /> : ""}
							</label>
							<div className="">
								<select
									data-test="signup-companyName"
									name="companyName"
									onChange={handleChange}
									className="form-control "
									id="companyName"
									aria-label="Choose Your Company"
									// placeholder={t("Company Name")}
								>
									<option value="">Select Company</option>
									{companyList.map((v, i) => {
										return <option value={v.id}>{v.name}</option>;
									})}
								</select>
								<span className="input-error" role="alert">
									{error.companyName}
								</span>
							</div>
						</div>
						{/*[END:COMPANYNAME]*/}
					</Row>
					<Row>
						{/*[START:ADDRESS]*/}
						<div className="form-group col-sm-12  theme-signup-address-finder">
							<div className="custom-tooltip-wrap ">
								<label htmlFor="addressFinder" className="col-form-label">
									<Trans>lang_key_select_address</Trans>
									{validation["address"] && validation["address"].mandatory ? <Required /> : ""}
								</label>
								<OverlayTrigger trigger={["hover", "focus"]} placement="auto" overlay={tooltip}>
									<Button className="default" id="Help-Address">
										?
									</Button>
								</OverlayTrigger>
							</div>
							<div aria-describedby="Help-Address">
								<GooglePlaces
									getPlaceHandler={googleSelectedAddressHandler}
									// placeholder={t("lang_key_select_address")}
								/>
								<span className="input-error" role="alert">
									{error.address}
								</span>
							</div>
						</div>
						{/*[END:ADDRESS]*/}
					</Row>
					<Row>
						{/*[START:SUITE]*/}
						<div className="form-group col-sm-6 theme-signup-suite">
							<label htmlFor="suite" className="col-form-label">
								<Trans>lang_key_add_suite</Trans>
								{validation["suite"] && validation["suite"].mandatory ? <Required /> : ""}
							</label>
							<div className="">
								<input
									type="text"
									data-test="register-state"
									name="suite"
									value={data.suite}
									onChange={handleChange}
									className="form-control"
									id="suite"
									aria-label="Mailing Address"
									aria-autocomplete="address"
									// placeholder={t("lang_key_add_suite")}
								/>
								<span className="input-error" role="alert">
									{error.suite}
								</span>
							</div>
						</div>
						{/*[END:SUITE]*/}

						{/*[START:CITY]*/}
						<div className="form-group col-sm-6 theme-signup-city">
							<label htmlFor="city" className="col-form-label">
								<Trans>City</Trans>
								{validation["city"] && validation["city"].mandatory ? <Required /> : ""}
							</label>
							<div className="">
								<input
									type="text"
									data-test="signup-city"
									name="city"
									value={data.city}
									onChange={handleChange}
									className="form-control"
									id="city"
									aria-label="Your City"
									aria-autocomplete="city"
									// placeholder={t("City")}
								/>
								<span className="input-error" role="alert">
									{error.city}
								</span>
							</div>
						</div>
						{/*[END:CITY]*/}
					</Row>

					<Row>
						{/*[START:STATE]*/}
						<div className="form-group col-sm-6 theme-signup-state">
							<label htmlFor="state" className=" col-form-label">
								{t("lang_key_add_state")}
								{validation["state"] && validation["state"].mandatory ? <Required /> : ""}
							</label>
							<div className="">
								<input
									type="text"
									data-test="register-state"
									name="state"
									value={data.state}
									onChange={handleChange}
									className="form-control"
									id="state"
									aria-label="Your State"
									aria-autocomplete="state"
									// placeholder={t("lang_key_add_state")}
								/>
								<span className="input-error" role="alert">
									{error.state}
								</span>
							</div>
						</div>
						{/*[END:STATE]*/}

						{/*[START:ZIP]*/}
						<div className="form-group col-sm-6 theme-signup-zip">
							<label htmlFor="zip" className="col-form-label">
								<Trans>lang_key_add_zip</Trans>
								{validation["zip"] && validation["zip"].mandatory ? <Required /> : ""}
								{/* <i aria-hidden="true" className="mandate">*</i><i class="visible-hidden">required</i> */}
							</label>
							<div className="">
								<input
									type="text"
									data-test="signup-zip"
									name="zip"
									value={data.zip}
									onChange={handleChange}
									className="form-control"
									id="zip"
									aria-label="Zip Code"
									aria-autocomplete="postal-code"
									// placeholder={t("lang_key_add_zip")}
								/>
								<span className="input-error" role="alert">
									{error.zip}
								</span>
							</div>
						</div>
						{/*[END:ZIP]*/}
					</Row>

					<Row>
						{/*[START:BIRTH]*/}
						<div className="form-group col-sm-6 theme-signup-birth">
							<div className="custom-tooltip-wrap ">
								<label htmlFor="dobenter" className="col-form-label theme-signup-label-birth">
									<Trans>Birth Date</Trans>
									{validation["birth"] && validation["birth"].mandatory ? <Required /> : ""}
								</label>
								<OverlayTrigger trigger={["hover", "focus"]} placement="auto" overlay={ssnTooltip}>
									<Button className="default">?</Button>
								</OverlayTrigger>
							</div>
							<div className="">
								<InputMask
									className={underAge ? "form-control show-placegholder control-disabled" : "form-control show-placegholder"}
									mask="00/00/1000"
									maskChar={""}
									value={data.birth}
									id="dobenter"
									disabled={underAge}
									formatChars={formatChars}
									onChange={(e) => {
										if (!underAge) handleDate(e.target.value);
									}}
									placeholder="MM/DD/YYYY"></InputMask>
								<span className="input-error" role="alert">
									{error.birth}
								</span>
							</div>
						</div>
						{/*[END:BIRTH]*/}
						{/*[START:PHONE]*/}
						<div className="form-group col-sm-6 theme-signup-lastname">
							<label htmlFor="phone-number" className=" col-form-label">
								<Trans>Phone Number</Trans>
								{validation["cellPhone"] && validation["cellPhone"].mandatory ? <Required /> : ""}
							</label>
							<div className="">
								<input
									type="text"
									data-test="register-phoneNumber"
									name="cellPhone"
									onChange={handleChange}
									className="form-control"
									id="phone-number"
									aria-label="Your Phone Number"
									aria-autocomplete="contact-number"
									// placeholder={t("Phone Number")}
								/>
								<span className="input-error" role="alert">
									{error.cellPhone}
								</span>
							</div>
						</div>
						{/*[END:PHONE]*/}
					</Row>
					<Row>
						{/*[START:SSNUMBER]*/}
						<div className="form-group col-sm-6 theme-signup-lastname">
							<div className="custom-tooltip-wrap ">
								<label htmlFor="socialSecurityNumber" className=" col-form-label">
									{/* <Trans>Cell Phone</Trans> */}
									<Trans>lang_key_SSN</Trans>
									{validation["socialSecurityNumber"] && validation["socialSecurityNumber"].mandatory ? <Required /> : ""}
								</label>
								<OverlayTrigger trigger={["hover", "focus"]} placement="auto" overlay={ssnTooltip}>
									<Button className="default">?</Button>
								</OverlayTrigger>
							</div>
							<div className="">
								<InputMask
									className="form-control"
									mask="999-99-9999"
									maskChar={""}
									value={data.socialSecurityNumber}
									id="socialSecurityNumber"
									name="socialSecurityNumber"
									onChange={handleChange}
									aria-label="Create Social Security Number"
									// placeholder={t("lang_key_SSN")}
								></InputMask>
								<span className="input-error" role="alert">
									{error.socialSecurityNumber}
								</span>
							</div>
						</div>
						{/*[END:SSNUMBER]*/}
					</Row>

					<Row>
						{/* <div className="mt-3 col-12">
							<div className="v-align-baseline form-check">
								<input className="form-check-input" type="checkbox" id="agree_to_opt_in" name="agree_to_opt_in" onChange={(e) => termConditionChkHandler(e.target.checked, "agree_to_opt_in")} />{" "}
								<label htmlFor="agree_to_opt_in" className="form-check-label">
									{" "}
									By registering, I confirm I want to receive emails from P&G Professional and other trusted{" "}
									<a href="https://us.pg.com/brands/" target="_blank">
										P&G brands
									</a>{" "}
									and programs. Click to read{" "}
									<a href="https://termsandconditions.pg.com/en-us/" target="_blank">
										<Trans>lang_key_termsConditions</Trans>
									</a>{" "}
									<Trans>lang_key_and</Trans>{" "}
									<a href="https://privacypolicy.pg.com/en/" target="_blank">
										<Trans>lang_key_privacypolicy</Trans>
									</a>
									.
									<Required />
									<br />
									<span className="input-error" role="alert">
										{error.agree_to_opt_in}
									</span>
								</label>
							</div>
						</div>
						<div className="mt-3 col-12">
							<div className="btm-1 d-flex v-align-baseline form-check">
								<input type="checkbox" id="agree_to_program_scope" name="agree_to_program_scope" className="form-check-input" onChange={(e) => termConditionChkHandler(e.target.checked, "agree_to_program_scope")} />{" "}
								<label htmlFor="agree_to_program_scope" className="form-check-label">
									{" "}
									This program scope concerns qualifying U.S. sales to private companies of certain P&G products outlined in the listed promotions (which are limited to P&G Fabric, Home and Family Care products). Sales outside of this scope will not qualify. For the avoidance of doubt, P&G will not be providing (or permitting program participants or anyone else to provide on its
									behalf) any incentives i) to government employees (including without limitation school or university employees, buyers in military channels, hospital administrators, employees of skilled nursing/assisted living facilities, public procurement managers, or any other federal, state, or local entity or employee); ii) to any health care professionals or institutions
									(including without limitation doctors, hospitals, clinics, dentists, nurses, hygienists, or any other patient care setting, whether public or private in their practice); iii) in connection with sales of P&G health care or oral care products; or iv) in connection with sales to organizations that seek or receive state or federal health care program reimbursement
									for P&G products. Program participants agree that they will not claim or offer incentives for sales in violation of this program scope. Please see{" "}
									<a href="/termsandconditions" target="_blank">
										P&G Professional Partner Rewards Terms & Conditions
									</a>{" "}
									for full details. <Required />
									<br />
									<span className="input-error" role="alert">
										{error.agree_to_program_scope}
									</span>
								</label>
							</div>
						</div> */}
						<div className="mt-3 col-12">
							<div className="btm-1 d-flex v-align-baseline form-check">
								<input type="checkbox" id="agree_to_png_term" name="agree_to_png_term" className="form-check-input" checked={data.additionalInfo.agree_to_png_term} onChange={(e) => termConditionChkHandler(e.target.checked, "agree_to_png_term")} />{" "}
								<label htmlFor="agree_to_png_term" className="form-check-label">
									This P&G Program scope concerns qualifying U.S. sales made by P&G to participating private companies of certain P&G products outlined in the listed promotions (which are strictly limited to P&G Fabric, Home and Family Care products). P&G Program participants agree that they will not claim or offer incentives for sales in violation of this P&G Program scope nor
									offer, share, pay, or otherwise provide any portion of incentives earned under the P&G Program to others in order to induce the purchase of P&G products. Participation in the P&G Program does not create an employment or agency relationship with P&G and requires the prior approval of a participant’s employer. Please see{" "}
									<a href="/termsandconditions" target="_blank">
										P&G Professional Partner Rewards Terms & Conditions
									</a>{" "}
									for full details.
									<Required />
									<br />
									<span className="input-error" role="alert">
										{error.agree_to_png_term}
									</span>
								</label>
							</div>
						</div>
						{/* <div className="mt-3 col-12">
							<div className="btm-1 d-flex v-align-baseline form-check">
								<input type="checkbox" id="agree_to_png_policy" name="agree_to_png_policy" className="form-check-input" onChange={(e) => termConditionChkHandler(e.target.checked, "agree_to_png_policy")} />{" "}
								<label htmlFor="agree_to_png_policy" className="form-check-label">
									{" "}
									I have read and agree to the{" "}
									<a href="https://termsandconditions.pg.com/en-us/" target="_blank">
										P&G Terms & Conditions
									</a>{" "}
									and{" "}
									<a href="https://privacypolicy.pg.com/en/" target="_blank">
										Privacy Policy
									</a>
									.
									<Required />
									<br />
									<span className="input-error" role="alert">
										{error.agree_to_png_policy}
									</span>
								</label>
							</div>
						</div> */}
					</Row>

					{/*[START:GOOGLECAPTCHA]*/}
					{captchaEnable ? (
						<div className="form-group row mt-5">
							<label htmlFor="g-recaptcha-response" className="col-sm-12 col-form-label d-none" aria-hidden="true">
								Google captcha
							</label>
							<div className="col-sm-12">
								<Captcha align={"left"} reset={resetCaptchaCnt} parentCallback={setCaptchaValue} />
								<p className="input-error" style={{ marginTop: "-12px" }} role="alert">
									{" "}
									{error.captcha}
								</p>
							</div>
						</div>
					) : (
						""
					)}
					{/*[END:GOOGLECAPTCHA]*/}
					<div className="text-center">
						<button type="button" id="register-submit-btn" data-test="register-submit" onClick={onSubmit} className="btn btn-primary">
							<Trans>REGISTER</Trans>
						</button>
					</div>
				</form>
			</div>
			<Modal show={show} onHide={handleClose} backdrop="static" size="lg" keyboard={false}>
				<Modal.Body>
					<TermsAndCondition pdflink={true} />
					<div className="justify-content-center text-center mt-2">
						<button className="btn btn-modal pl-5 pr-5" onClick={handleClose}>
							I ACCEPT
						</button>
						<div className="mt-4">
							<button className="btn btn-modal" onClick={handleNotAgree}>
								I do not wish to participate in the P&G Professional Partner Rewards Program
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<Modal show={showAgeGate} onHide={handleCloseAgeGate} backdrop="static" keyboard={false}>
				<Modal.Body>
					<div className="m-4 text-center">
						<p>
							We're sorry, but we can't accept your messages or personal information because you do not meet our eligibility requirements. But that doesn't mean you can't explore the rest of our site and learn more about us! Check out{" "}
							<a href="https://us.pg.com/" target="_blank">
								www.pg.com
							</a>{" "}
							for information about P&G and its brands.
						</p>
					</div>
					<div className="justify-content-center text-center mt-2">
						<Button variant="secondary" className="btn btn-modal" onClick={handleCloseAgeGate}>
							OK
						</Button>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default connect(null, { signup, login })(IsLoadingHOC(Register, "Wait ....."));
