import React from "react";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { connect } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { forgetPassword } from "../../redux/actions";
const ForgetPassword = ({ setLoading, login, forgetPassword }) => {
	const history = useHistory();
	return (
		<div className="container">
			<div className="resetpassword-page theme-reset-password-custom">
				<div className="row justify-content-center">
					<div className="col-md-5">
					
						<Formik
							initialValues={{ email: "" }}
							validationSchema={Yup.object({
								email: Yup.string()
									.email("Invalid email address")
									.required("Email should not be empty"),
							})}
							onSubmit={(values, { setSubmitting }) => {
								//console.log(values);
								setLoading(true);
								forgetPassword(values.email)
									.then((response) => {
										//console.log(response);
										setSubmitting(false);
										setLoading(false);
										toast.success(response.message);
										history.push("/login");
									})
									.catch((error) => {
										console.log(error);
										setSubmitting(false);
										setLoading(false);
										error.forEach((item) => {
											if (item.code === "NOT FOUND") {
												toast.error("Please provide your registered email and try again.");
											}else{
												toast.error(item.message);
											}
										});
									});

								// setTimeout(() => {
								// 	alert(JSON.stringify(values, null, 2));
								// 	setSubmitting(false);
								// }, 400);
							}}>
							<Form>
								<h1 className="text-center">Forgot your password?</h1>
								<div className="form-group theme-form-email mt-5">
									<label htmlFor="staticEmail" className="col col-form-label">
										Email
									</label>
									<div className="col">
										<Field data-test="reset-password" name="email" type="email" className="form-control" id="staticEmail" />
										<div className="input-error"><ErrorMessage name="email" /></div>
										
										{/* <input data-test="reset-password" type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)}  className="form-control" id="staticEmail" placeholder="Email" /> */}
									</div>
								</div>

								<div className="justify-content-center">
									<div className="col text-center">
										<button data-test="reset-password-button" type="submit" className="btn btn-primary">
											Generate New Password
										</button>
									</div>
								</div>
							</Form>
						</Formik>
					
					</div>
				</div>
			</div>
		</div>
	);
};

export default connect(null, { forgetPassword })(IsLoadingHOC(ForgetPassword, "Wait ...."));
