import React, { useState } from "react";
import { Row, Col, Accordion, Card, Button, Nav, Section } from "react-bootstrap";
const FrequentlyAskedQuestions = () => {
	const [data, setData] = useState([
		{
			id: 0,
			clicked: false,
			name: "What is the P&G Professional Partner Rewards program?",
			content:
				"<p>P&G Professional Partner Rewards is a loyalty program that rewards Distributor Sales Representatives (DSR) for growing P&G brand sales.  For each incremental case sold, a DSR will earn points and redeem for digital Amazon, Mastercard and VISA cash rewards as specified herein when specified requirements are fulfilled.  This program enables DSRs and P&G Professional to partner for growth and win with the customers. <strong>DSRs Sell. Earn. Repeat.</strong></p><p><strong>Sell:</strong> P&G Professional’s portfolio of recognizable brands that are trusted to overcome the toughest professional challenges.</p><p><strong>Earn:</strong> Rewards for growing brand sales.</p><p><strong>Repeat:</strong> Come back every quarter for new earning opportunities. Your customers will come back for brands that get the job done.</p>",
		},
		{ id: 1, clicked: false, name: "How do I earn P&G Professional Partner Reward points?", content: "Sell incremental cases to your customers from the prior quarter for the specified brand in the respective campaign.  Upload your sales at the end of each quarter for validation." },
		{
			id: 2,
			clicked: false,
			name: "What can I do with my P&G Professional Partner Reward points?",
			content:
				"<p>You can redeem your P&G Professional Partner Reward points for a digital Amazon, Mastercard and VISA cash reward. The digital Amazon, Mastercard and VISA cards are redeemable with the following quantity of P&G Professional Partner Reward points:<p/> <ul><li>$25 digital Amazon, Mastercard and VISA Card: Redeem 25 P&G Professional Partner Reward points</li><li>$50 digital Amazon, Mastercard and VISA Card: Redeem 50 P&G Professional Partner Reward points</li><li>$100 Digital Amazon, Mastercard and VISA Card: Redeem 100 P&G Professional Partner Reward points</li><li>$150 Digital Amazon, Mastercard and VISA Card: Redeem 150 P&G Professional Partner Reward points</li></ul>",
		},
		{ id: 3, clicked: false, name: "Is there a limit to how many reward dollars I can earn per calendar year? ", content: "The maximum value of any incentives redeemed shall not exceed $5,000 USD per calendar year per participant." },
		{ id: 4, clicked: false, name: "How many times can I submit the same valid sales data?", content: "You can only submit valid sales data once; sales data submitted more than once will be rejected and the account(s) involved may be shut down if fraudulent submissions occur." },
		{
			id: 5,
			clicked: false,
			name: "Why was my sales data submission not approved?",
			content:
				"<p>Your online registration and sales data upload must be complete to be approved. Please double-check that:</p><ul><li>Your online registration form is complete with the required information.</li><li>Your submitted proof of sale data show both this and last quarters’ sales for the participating brand.</li><li>Your data must show that your sales were made between October 1-December 31, 2022 11:59:59 PM EST.</li><li>Your data must be clear and legible and include your name, the description of the brand/skus sold, the Case UPC and most be in full Case quantities.</li><li>Your data must not have been previously submitted by you or any other individual.</li></ul>",
		},
		{ id: 6, clicked: false, name: "What do I do if I am having trouble participating in the Program? ", content: "Please click on the Support tab for support. Complete and submit the form.  Your questions will be answered by one of our Support Specialists. You may also contact your P&G Account Executive for further information." },
		{ id: 7, clicked: false, name: "What do I do if I have additional questions?", content: "Review the Terms & Conditions for the program for additional details. If the Terms & Conditions have not answered your question, please click on the Support tab, complete and submit the form. You may also contact your P&G Account Executive for further information." },
		{ id: 8, clicked: false, name: "What products are eligible for points in the Program? ", content: "This program scope concerns qualifying U.S. sales to private companies of certain P&G products outlined in the listed promotions (which are limited to P&G Fabric, Home and Family Care products).  Sales outside of this scope will not qualify." },
	]);
	const [faqStatus, setFaqStatus] = useState(false);
	const toggleHandler = (i) => {
		let tempData = JSON.parse(JSON.stringify(data));
		tempData[i].clicked = tempData[i].clicked ? false : true;
		setData(tempData);
	};
	const bulkActionHandler = () => {
		let status = !faqStatus;
		setFaqStatus(status);
		let tempData = data.map((v) => ({ ...v, clicked: status }));
		setData(tempData);
	};
	return (
		<section role="contentinfo" aria-label="FAQ">
			<Row className="justify-content-md-center section-content" id="faq-content">
				<Col md={12}>
					<h1 className="section-heading text-center mb-5">FAQ</h1>
				</Col>
				<Col md={12} className="text-right">
					<Button onClick={bulkActionHandler} variant="link">
						{!faqStatus ? "Open All" : "Close All"}
					</Button>
				</Col>
				<Col md={12} className="faq-page p-0">
					<Accordion>
						{data.map((v, i) => {
							return (
								<Card>
									<Card.Header>
										<Accordion.Toggle activeKey={v.clicked} as={Nav} variant="link" className="p-0" onClick={(e) => toggleHandler(i)} eventKey={v.id}>
											<Row style={{ cursor: "pointer" }}>
												<Col className="col-11">{v.name}</Col>
												<Col className="col-1 text-right pl-0 faq-line-icon">{v.clicked ? "−" : "+"}</Col>
											</Row>
										</Accordion.Toggle>
									</Card.Header>
									{v.clicked === true ? (
										<Accordion>
											<Card.Body dangerouslySetInnerHTML={{ __html: v.content }} />
										</Accordion>
									) : (
										<Accordion.Collapse>
											<Card.Body dangerouslySetInnerHTML={{ __html: v.content }} />
										</Accordion.Collapse>
									)}
								</Card>
							);
						})}
					</Accordion>
				</Col>
			</Row>
		</section>
	);
};

export default FrequentlyAskedQuestions;
