import React, { useEffect, useState } from "react";
import Card from "../Card";
import { rewardsListAction, balanceGetAction,redeemRewardOrderAction } from "../../redux/actions";
import { Button, Container, Col, Row, Modal, CloseButton } from "react-bootstrap";
import IsLoadingHOC from "../IsLoadingHOC";
import { Trans, useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";

const RewardsList = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const user = useSelector(({ user }) => user);
	const [rewardsList, setRewardsList] = useState([]);
	const [data, setData] = useState({});
	const [pageRedirect, setPageRedirect] = useState(false);
	const [selectedRewards, setSelectedRewards] = useState("");
	const [show, setShow] = useState(false);
	const handleClose = () => {
		setShow(false);
	};
	const handleShow = (type, tag) => {
		if (type === 1) {
			var arr = JSON.parse(JSON.stringify(data[tag]));
			arr = arr.sort(function(a, b) {
				if ("".localeCompare === undefined) {
					if (a.itemName > b.itemName) return 1;
					if (a.itemName < b.itemName) return -1;
					return 0;
				} else {
					return a.itemName.localeCompare(b.itemName, undefined, {
						numeric: true,
						sensitivity: "base",
					});
				}
			});
			setRewardsList(arr);
			setShow(true);
		} else if (type === 2) {
			if (selectedRewards) {
				redeemReward();
				handleClose();
			}
		}
	};
	const redeemReward = () => {
		props.setLoading(true);
		redeemRewardOrderAction(selectedRewards)
			.then((response) => {
				setRewardsList([]);
				setSelectedRewards("");
				if (response.data) {
					dispatch(balanceGetAction());
					toast.success(`${t("You've redeemed a reward successfully, Please check you mail.")}.`);
				} else {
					if (response.httpCode === 406) {
						toast.error(`${t("Sorry")}! ${t("You've already redeemed this reward")}.`);
					} else if (response.httpCode === 403) {
						toast.error(`${t("Sorry")}! ${t("This reward is temporarily out of stock")}.`);
					} else if (response.httpCode === 412) {
						toast.error(`${t("Sorry")}! ${t("You don't have this much points to redeem it")}.`);
					} else {
						toast.error(`${t("Error")}: ${t("Something went wrong")}!`);
					}
				}
				props.setLoading(false);
			})
			.catch((err) => {
				setRewardsList([]);
				setSelectedRewards("");
				toast.error(`${t("Error")}: ${t("Something went wrong")}!`);
				props.setLoading(false);
			});
	};
	useEffect(() => {
		dispatch(balanceGetAction());
		getRewardsList();
		// eslint-disable-next-line
	}, []);
	const getRewardsList = () => {
		props.setLoading(true);
		rewardsListAction()
			.then((response) => {
				if (response.data) {
					let rewards = {};
					for (let i in response.data) {
						if (response.data[i]["tags"]) {
							for (let j in response.data[i]["tags"]) {
								if (!rewards.hasOwnProperty(response.data[i]["tags"][j])) {
									rewards[response.data[i]["tags"][j]] = [];
								}
								rewards[response.data[i]["tags"][j]].push(response.data[i]);
							}
						}
					}
					setData(rewards);
				}
				props.setLoading(false);
			})
			.catch((ex) => {
				console.log("Error In API Call", ex);
				props.setLoading(false);
			});
	};
	const cardClick = async (redirectUrl, redeemPoints) => {
		if (user.pointBalance < redeemPoints) {
			return false;
		}
		setPageRedirect(redirectUrl);
	};

	if (pageRedirect) {
		return <Redirect push to={pageRedirect} />;
	} else {
		return (
			<Container className="rewards-page">
				<Row>
					<Col>
						<h1 className="mt-5">
							<Trans>Rewards</Trans>
						</h1>

						<div className="mt-4 mb-5 text-center">
							Hi <span>{user.name ? user.name : user.username}</span>.<p>Thank you for partnering with P&G Professional. Turn your points into something amazing!</p>
						</div>
					</Col>
				</Row>

				<Row className="justify-content-center card-cta">
					<Col md={6}>
						<Row>
							{Object.keys(data).length > 0
								? Object.keys(data)
										.sort()
										.map((v, i) => {
											return (
												<Col md={6} key={`card-${i}`}>
													<Card className="text-center">
														<div className="text-center card-p" onClick={() => handleShow(1, v)}>
															<div className="reward-title">{v}</div>
															<div>
																<img className="w-100" src={`/public/assets/images/rewards_brand/${v}.png`} alt={v} />
															</div>
															<button className="btn btn-primary px-2">REDEEM NOW</button>
														</div>
													</Card>
												</Col>
											);
										})
								: ""}
						</Row>
					</Col>
				</Row>
				<Modal show={show}>
					<Modal.Body>
						<div id="sales-requirment-points" className="text-center rewards-page-modal">
							<div>
								Current Points: <strong>{user.pointBalance}</strong>
							</div>
							<div className="mt-3 mb-4">*Note: 1 point = $1 in the program</div>
							<div className="justify-content-center row">
								<div className="form-group col-md-9">
									<label htmlFor={"reward-id"} aria-hidden="true" className="d-none">
										Select Rewards
										{/* {validation["rewards"] && validation["rewards"].mandatory ? <span className="ele-mandate">*</span> : ""} */}
									</label>
									<select
										onChange={(e) => {
											setSelectedRewards(e.target.value);
										}}
										className="form-control"
										name="rewards"
										value={selectedRewards}
										inputProps={{ id: "reward-id" }}
										data-test="rewards-ddlist"
										id="rewards">
										<option value="">{t("Select an reward")}</option>
										{rewardsList.map((v, i) => {
											return <option value={v.id}>{v.itemName}</option>;
										})}
									</select>
									{/* <span className="input-error" id="name-helper-text">
									{error.rewards}
								</span> */}
								</div>
							</div>
							<div className="text-center">
								<Button type="button" disabled={!selectedRewards} onClick={() => handleShow(2)} className="btn btn-modal" id="upload-btn">
									REDEEM NOW
								</Button>
							</div>
							<div className="text-center mt-3">
								<Button type="button" onClick={handleClose} className="btn btn-modal">
									NO, I CHANGED MY MIND
								</Button>
							</div>
						</div>
					</Modal.Body>
				</Modal>
			</Container>
		);
	}
};

export default IsLoadingHOC(RewardsList, "Loading....");
