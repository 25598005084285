import React from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import TopRight from "./TopRight";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { saveSelectedMenu } from "../redux/actions";
import { useSelector, useDispatch } from "react-redux";

const Menu = ({ data }) => {
	const { t } = useTranslation();
	const user = useSelector(({ user }) => user);
	const dispatch = useDispatch();

	const scroll = (el) => {
		dispatch(saveSelectedMenu(el.id));
		const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
		const yOffset = -80;
		window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
		document.getElementById("navbarSupportedContent").classList.remove("show");
	};
	const navclose = () => {
		document.getElementById("navbarSupportedContent").classList.remove("show");
	};

	return (
		<Fragment>
			<button id="button-navbar-toggler" className="navbar-toggler mob-menu-pos" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span id="span-navbar-toggler-icon" className="navbar-toggler-icon"></span>
			</button>

			<div className="collapse navbar-collapse" id="navbarSupportedContent">
				<ul className="navbar-nav">
					{/*[START:VALIDATEPURCHASE]*/}
					{/*<li className="nav-item">
						<a id="href-validate-purchase" className="nav-link" href="#/">
							{t("Validate Purchase")}
						</a>
						<ul className="navbar-nav">
							{/*[START:SUBMITCODE]*}
							<li className="nav-item">
							<Link id="href-submit-code" to="/validate-code">
								{t("Submit Code")}
							</Link></li>
							{/*[END:SUBMITCODE]*}

							{/*[START:DIGITALMOVIE]*}
							<li className="nav-item">
								<a id="href-digital-movie" href="/">
									{t("Digital Movie")}
								</a>
							</li>
							{/*[END:DIGITALMOVIE]*}

							{/*[START:RECEIPTUPLOAD]*}
							<li className="nav-item">
							<Link id="href-receipt-upload" to="/upload-receipt">
								{t("Receipt Upload")}
							</Link></li>
							{/*[END:RECEIPTUPLOAD]*}

							{/*[START:ELIGIBLEMOVIES]*}
							<li className="nav-item">
							<Link id="href-eligible-products" to="/eligible-product">
								{t("Eligible Products")}
							</Link></li>
							{/*[END:ELIGIBLEMOVIES]*}
						</ul>
					</li>*/}
					{/*[END:VALIDATEPURCHASE]*/}

					{/*[START:HOME]*/}
					<li className="nav-item">
						<a className="nav-link nav-link-custom" id="href-home" href="/">
							{t("Home")}
						</a>
					</li>
					{/*[END:HOME]*/}
					{/*[START:HOW IT WORKS]*/}
					<li className="nav-item ">
						<HashLink className="nav-link nav-link-custom" id="href-participate" to="/#how-it-works" scroll={scroll}>
							{t("How It Works")}
						</HashLink>
					</li>
					{/*[END:HOW IT WORKS]*/}

					{/*[START:REGISTER]*/}
					{!user.accessToken ? (
						<li className="nav-item">
							<a id="href-register" className="nav-link" href="/register">
								{t("Register")}
							</a>
						</li>
					) : (
						<li className="nav-item">
							<a id="href-rewards" className="nav-link" href="/rewards">
								{t("Rewards")}
							</a>
						</li>
					)}
					{/*[END:REGISTER]*/}
					{!user.accessToken ? (
						<li className="nav-item">
							<a id="href-login" className="nav-link" href="/login">
								{t("Login")}
							</a>
						</li>
					) : (
						""
					)
					// (
					// 	<li className="nav-item">
					// 		<a id="href-upload" className="nav-link" href="/upload-receipt">
					// 			{t("Submit Proof of Sale")}
					// 		</a>
					// 	</li>
					// )
					}

					{/*[START:SUPPORT]*/}
					<li className="nav-item">
						<a id="href-support" className="nav-link" href="/support">
							{t("Support")}
						</a>
					</li>
					{/*[END:SUPPORT]*/}

					{/* My All-Access , Points History, My Rewards, Profile, Connect Movie ANYHERE, Logout */}
				</ul>
			</div>
			{/* <TopRight /> */}
		</Fragment>
	);
};

export default Menu;
